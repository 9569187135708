%medium-button-styles {
  padding: $size-8 $size-12;
  font-size: $size-14;
  font-weight: $semibold;
  line-height: 1.3;
  border-radius: $radius-8;
  height: $medium-button-height;
}
body {
  .mat-mdc-button[disabled]:not(.mat-primary) {
    pointer-events: initial !important;
    background: $gray-50 !important;
    color: $gray-5 !important;
  }
  button {
    font-size: $size-14;
  }
  .mat-button,
  .mdc-button {
    padding: $size-8 $size-12;
    text-decoration: none;
  }
  .mat-mdc-icon-button {
    svg, img {
      height: auto;
      width: auto;
    }
  }
  .mdc-button__label {
    max-width: 100%;
  }
  .mat-button,
  .mdc-button {
    min-width: auto;
    transition: 0.3s;
    font-family: $font-primary;
    @include flex-combinator(row, nowrap, center, center);
    @include inline-flex;
    height: auto;

    .mdc-button__ripple {
      display: none;
    }

    &:hover {
      transition: 0.3s;
      text-decoration: none;
      text-decoration: none;
    }

    .mdc-button__ripple,
    .mat-ripple,
    .mat-mdc-button-ripple {
      display: none;
    }

    .mat-button-wrapper,
    .mdc-button__label {
      gap: $size-4;
      @include flex-combinator(row, nowrap, center, center);
    }

    &.mat-button-disabled,
    &[disabled="true"] {
      img {
        opacity: 0.6;
      }
      .down-icon {
        display: none;
      }
    }

    &.text-left {
      .mat-button-wrapper,
      .mdc-button__label {
        justify-content: flex-start;
        text-align: left;
        width: 100%;
      }
    }

    .material-icons {
      @include sequre(auto);
      margin: 0;
      min-width: 18px;
    }

    &.btn-normal,
    &.button-normal {
      padding: $size-10 $size-14;
      font-size: $size-16;
      font-weight: $semibold;
      line-height: 1.3;
      border-radius: $radius-8;
      min-height: $normal-button-height;

      .mat-button-wrapper,
      .mdc-button__label {
        gap: $size-8;
      }
      img {
        max-width: $size-20;
        max-height: $size-20;
      }
      .material-icons {
        font-size: $size-22;
        line-height: 1;
      }
      &[class*="-icon"] {
        padding: 0;
        width: $normal-button-height;
        min-width: $normal-button-height;
        &[class*="-action"] {
          background: transparent;
        }
        .button-label {
          display: none;
        }
      }
    }

    &[class*="btn-medium"],
    &[class*="button-medium"] {
      @extend %medium-button-styles;

      .mat-button-wrapper,
      .mdc-button__label {
        gap: $size-6;
      }
      img {
        max-width: $size-18;
        max-height: $size-18;
      }
      .material-icons {
        font-size: $size-20;
        line-height: 1;
      }
      &[class*="-icon"] {
        padding: 0;
        width: $medium-button-height;
        min-width: $medium-button-height;
        .button-label {
          display: none;
        }
      }

      &[class*="-icon-action"] {
        background: transparent;
      }
    }

    &[class*="btn-small"],
    &[class*="button-small"] {
      padding: $size-8 $size-12;
      font-size: $size-12;
      font-weight: $semibold;
      line-height: 1.3;
      border-radius: $radius-8;
      height: $small-button-height;

      .mat-button-wrapper,
      .mdc-button__label {
        gap: $size-4;
      }
      img {
        max-width: $size-16;
        max-height: $size-16;
      }
      .material-icons {
        font-size: $size-18;
        line-height: 1;
      }

      &[class*="-icon"] {
        padding: 0;
        width: $small-button-height;
        min-width: $small-button-height;
        &[class*="-action"] {
          background: transparent;
        }
        .button-label {
          display: none;
        }
      }
    }

    &[class*="btn-smaller"],
    &[class*="button-smaller"] {
      padding: $size-6 $size-10;
      font-size: $size-12;
      font-weight: $semibold;
      line-height: 1.3;
      border-radius: $radius-6;
      height: $smaller-button-height;

      .mat-button-wrapper,
      .mdc-button__label {
        gap: $size-4;
      }
      img {
        max-width: $size-14;
        max-height: $size-14;
      }
      .material-icons {
        font-size: $size-16;
        line-height: 1;
      }

      &[class*="-icon"] {
        padding: 0;
        width: $smaller-button-height;
        min-width: $smaller-button-height;
        &[class*="-action"] {
          background: transparent;
        }
        img {
          max-width: $size-14;
          max-height: $size-14;
        }
        .material-icons {
          font-size: $size-16;
        }
        .button-label {
          display: none;
        }
      }
    }
    // button-stroked(Font color, border color, background color on hover, color-on-hover, border-color-on-hover, shadow-color, color-on-disabled, border-color-on-disabled)
    &.mat-primary {
      &.mat-flat-button,
      &.mdc-button--unelevated {
        @include button-flat($primary, $white, $primary-darker, $white, $primary-lighter);
      }
      &.mat-stroked-button,
      &.mdc-button--outlined {
        @include button-stroked(
          $primary,
          $primary,
          $primary-lighter,
          $primary-darker,
          $primary-darker,
          $primary-lighter
        );
      }
      &[class*="-icon-action"] {
        &:hover {
          background-color: $primary-lighter;
        }
      }
    }

    &.mat-secondary {
      &.mat-flat-button,
      &.mdc-button--unelevated {
        @include button-flat($secondary, $primary-darker, $secondary-hover, $primary-darker, $secondary-disabled);
      }
      &[class*="-icon-action"] {
        &:hover {
          background-color: $primary-lighter;
        }
      }
    }
    // button-flat($bg, $color, $bg-hover, $color-hover, $shadow-solid)
    &.mat-light {
      &.mat-flat-button,
      &.mdc-button--unelevated {
        @include button-flat($white, $dark, $primary-lighter, $primary-darker, $secondary-lighter);
      }
    }

    &.mat-dark {
      &.mat-flat-button,
      &.mdc-button--unelevated {
        @include button-flat($gray-7, $white, $gray-8, $white, $primary-lighter);
      }
      &.mat-stroked-button,
      &.mdc-button--outlined {
        @include button-stroked($gray-7, $gray-3, $gray-50, $gray-7, $gray-3, $gray-1);
      }
    }

    &.mat-success {
      &.mat-flat-button,
      &.mdc-button--unelevated {
        @include button-flat($success, $white, $success-darker, $white, $success-lighter);
      }
      &[class*="-icon-action"] {
        &:hover {
          background-color: $success-light;
        }
      }
    }

    // button-stroked(text-color, border-color, bg-color-on-hover, text-color-on-hover, border-color-on-hover, focus-active-shadow-color)
    &.qb-button {
      @include button-stroked($qb-primary, $qb-primary, $qb-hover-bg, $qb-primary, $qb-primary, transparent);
    }

    &.mat-error,
    &.mat-delete,
    &.mat-danger {
      &.mat-flat-button,
      &.mdc-button--unelevated {
        @include button-flat($error, $white, $error-hover, $white, $error-1);
      }
      &.mat-stroked-button,
      &.mdc-button--outlined {
        @include button-stroked($error, $error-3, $error-50, $error-8, $error-3, $error-1);
      }
      &[class*="-icon-action"] {
        &:hover {
          background-color: $error-50;
        }
      }
    }

    &.btn-text,
    &.button-text,
    &.text-btn,
    &.text-button {
      background-color: transparent;
      color: $primary;
      font-weight: $semibold;
      font-size: $size-14;
      line-height: 1.43;
      border-radius: 0;
      border: 0;
      box-shadow: none;
      padding: 0;
      width: auto;
      height: auto;
      img {
        max-width: $size-16;
        max-height: $size-16;
      }

      .material-icons {
            font-size: $size-18;
            line-height: 1;
        }
        svg {
            @include sequre($size-16);
        }
        path {
            stroke: $primary;
        }

      [class^="fa-"] {
        font-size: $size-18;
      }

      &:hover,
      &:focus {
        color: $primary-darker;

        [class^="fa-"] {
          color: $primary-darker;
        }
      }

      &:disabled,
      &[disabled="true"] {
        color: $gray-7 !important;
        cursor: initial;
        [class^="fa-"] {
          color: $gray-4;
        }
      }
    }

    &.kebab-button {
      padding: $size-4;
      background: transparent;
      color: $gray-6;
      font-size: $size-18;
      transition: all 0s ease 0.3s;

      &:hover {
        color: $primary-darker;
        background: $primary-lighter;
        transition: all 0s ease 0.3s;
      }
    }

  }
  .mat-mdc-button .mat-mdc-button-touch-target,
  .mat-mdc-unelevated-button .mat-mdc-button-touch-target,
  .mat-mdc-raised-button .mat-mdc-button-touch-target,
  .mat-mdc-outlined-button .mat-mdc-button-touch-target {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate(0);
  }
}

.button-group {
  gap: $size-12;
  @include flex-combinator(row, wrap, center, initial);
}

body {
  .mat-button.button-label-xl {
    @media (max-width: 1800px) {
      padding: 0;
      .button-label,
      .dropdown-icon {
        display: none;
        img {
          display: none;
        }
      }
      &.button-normal {
        width: $normal-button-height;
      }
      &.button-medium {
        width: $medium-button-height;
      }
      &.button-small {
        width: $small-button-height;
      }
      &.button-smaller {
        width: $smaller-button-height;
      }
    }
  }
  .button-label-xl {
    @media (max-width: 1800px) {
      .mat-button,
      .mdc-button {
        // padding: 0;
        .button-label,
        .dropdown-icon {
          display: none;
          img {
            display: none;
          }
        }
        // &.button-normal {
        //   width: $normal-button-height;
        // }
        // &.button-medium {
        //   width: $medium-button-height;
        // }
        // &.button-small {
        //   width: $small-button-height;
        // }
        // &.button-smaller {
        //   width: $smaller-button-height;
        // }
      }
    }
  }
}
%custom-tooltip {
  position: absolute;
  background: #f2f3f2;
  border-radius: $size-12;
  padding: $size-10;
  width: 250px;
  font-size: $size-12;
  color: $black;
  white-space: normal;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.custom-tooltip {
  @extend %custom-tooltip;
  &-left {
    @extend %custom-tooltip;
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%);
  }
  &-right {
    @extend %custom-tooltip;
    top: 50%;
    right: 0;
     transform: translate(5%, -100%);
  }
  &-top {
    @extend %custom-tooltip;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}

// For Selection Hightlight in the Headers/Footers Selected Alignments.
.selected {
  background-color: $primary-1 !important;
  color: $primary-light !important;
}

body {
  %hover-label-styles {
    position: absolute !important;
    border-radius: $size-8;
    background-color: $white;
    border: $size-1 solid $black;
    color: $black;
    font-size: $size-12;
    font-weight: $semibold;
    padding: $size-8 $size-12;
    z-index: 2;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    opacity: 0;
    display: none;
  }
  .button-hover {
    &-left {
      .hover-label {
        @extend %hover-label-styles;
        left: 0;
        top: 50%;
        transform: translate(-100%, -50%);
      }
      &:hover {
        .hover-label {
          opacity: 1;
          @include inline-flex;
        }
      }
    }
    &-right {
      .hover-label {
        @extend %hover-label-styles;
        right: 0;
        top: 50%;
        transform: translate(100%, -50%);
      }
      &:hover {
        .hover-label {
          opacity: 1;
          @include inline-flex;
        }
      }
    }
  }
}


.v-tabs {
  overflow: visible;
  >.mat-mdc-tab-header,
  >mat-tab-header {
    overflow: visible;
    .mat-mdc-tab-label-container {
      overflow: visible;
    }
  }
}

.rating-button-group {
  @include flex-combinator(row, nowrap, center, center);
  gap: $size-8;

  button {
    img {
      width: $size-34 !important;
      min-width: $size-34 !important;
      height: $size-34 !important;
      filter: grayscale(1);
    }

    &.active,
    &.selected {
      background-color: transparent !important;
      img {
        filter: grayscale(0);
      }
    }
  }
}
