// @import '../global/global';

.stepper  {
  width: 100%;
  @include flex-combinator(row, nowrap, initial, initial);

  .step-item {
      position: relative;
      &:after {
          content: '';
          height: 2px;
          position: absolute;
          width: 100%;
          background-color: $gray-3;
          top: 15px;
          left: 0;
      }
      &:first-child {
          &:after {
              width: calc(50% + 1rem);
              left: calc(50% - 1rem);
          }
      }
      &:last-child {
          &:after {
              width: 50%;
          }
      }
      &:not(:last-child) {
          padding-right: 2rem;
      }
  }

  .step-item-inner {
      position: relative;
      z-index: 1;
      text-align: center;
      @include flex-combinator(column, nowrap, center, center);
      .circle-outer {
          @include circle(2rem);
          background-color: $gray-1;
          margin-bottom: 1.25rem;
          position: relative;
          @include flex-combinator(row, nowrap, center, center);

          &:before {
              content: '';
              background-color: $white;
              @include circle(0.625rem);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 2;
          }

          .step-circle {
              @include circle(2rem);
              background-color: $primary;
              box-shadow: 0 0 0 4px $primary-1;
              position: relative;
              overflow: auto;
              &.completed {
                  z-index: 2;
                  box-shadow: none;

                  &:before {
                      content: "\f00c";
                      font-family: 'Font Awesome 6 Pro' !important;
                      @include circle(100%);
                      @include flex-combinator(row, nowrap, center, center);
                      font-size: $size-20;
                      color: $white;
                  }
              }
          }
      }
  }
  .step-item-inner-next {
      @include flex-combinator(column, nowrap, center, center);
  }
}



mat-stepper {
    &.countable-stepper {
      background-color: Transparent;
      background-color: Transparent;
        gap: $size-24;
        @include flex-combinator(row, wrap, flex-start, flex-start);
        @media (max-width:991px) {
            @include flex-direction(column);
        }

        .mat-horizontal-stepper-wrapper {
          gap: $size-20;
          flex: 1;
          width: 100%;

          .mat-horizontal-content-container {
            padding: 0;
          }
        }

        .stepper-body-main {
          @include flex-combinator(column, nowrap, initial, initial);
        }

        .mat-horizontal-stepper-header-container {
            width: 100%;

            @media (max-width: 991px) {
              @include flex-combinator(column, nowrap);
            }
            @media (max-width:667px) {
              padding-left: $size-12;
            }
            .mat-step-header {
              height: auto;
              padding: $size-8;
              width: 100%;
              overflow: visible;
              gap: $size-8;
              position: relative;
              @include flex-combinator(column, nowrap, center, flex-start);

                .mat-step-header-ripple {
                  display: none !important;
                }

              @media (max-width: 991px) {
                @include flex-combinator(row, nowrap, center);
                padding: $size-8 0;
                width: auto;
                align-self: flex-start;
              }

              &:hover,
              &:focus {
                  background-color: transparent;
              }

              &[aria-selected="false"] {
                  .mat-step-label {
                      color: $gray-7;
                  }
              }

              .mat-step-icon {
                  @include sequre($size-32);
                  background-color: $gray-1;
                  margin-right: $size-16;
                  font-weight: $semibold;
                  font-family: $font-primary;
                  font-size: $size-20;
                  @include flex-combinator(row, nowrap, center, center);
                  margin: 0;
                  position: relative;
                  z-index: 2;



                  &[class*="selected"] {
                      background-color: $primary-1;
                      box-shadow: 0 0 0 4px #F2F9FF;
                      color: $primary-darker;
                  }

                  &[class*="state-edit"],
                    &[class*="done"] {
                      background-color: $primary-darker;
                      .mat-step-icon-content {
                        .mat-icon {
                            display: none;
                        }
                          &:after {
                            content: "\f00c";
                            font-family: 'Font Awesome 6 Pro' !important;
                            @include circle(100%);
                            @include flex-combinator(row, nowrap, center, center);
                            font-size: $size-20;
                            color: $white;
                          }
                      }
                  }
              }

              .mat-step-label {
                  color: $gray-7;
                  font-size: $size-16;
                  font-weight: $semibold;

                  &[class*="selected"],
                  &[class*="active"] {
                      color: $primary-darker;
                  }
                  .title {
                      overflow: hidden;
                      text-overflow: ellipsis;
                      width: 100%;
                      font-family: $font-primary;
                  }
              }

                &:not(:first-child) {
                    &:after {
                        height: $size-2;
                        width: 100%;
                        background: $primary-darker;
                        content: '';
                        position: absolute;
                        right: 50%;
                        top: $size-24;
                        z-index: 1;
                        border-radius: $size-4;
                        @media (max-width: 991px) {
                          left: $size-16;
                          width: $size-1;
                          height: 100%;
                          top: -50%;
                        }
                    }
                }
                &[ng-reflect-state="edit"] {
                    &:not(:first-child) {
                        &:after {
                            background: $primary-darker;
                        }
                    }
                }
            }

            .mat-stepper-horizontal-line {
                display: none;
            }

            .mat-step-header[aria-selected="true"] ~ .mat-step-header:after {
              background: $gray-2;
            }
        }


        >[class*="-content-container"] {
            max-width: 100%;
            flex: 1;
            padding: 0;
        }
    }
}


.mat-step-label {
  &.mat-step-label-selected {
    font-size: $size-14;
    font-weight: $semibold;
  }
  &.mat-step-label-active {
    color: $black;
  }
}


body {
  mat-stepper.ai-uses {
    background: transparent;
    height: 100%;
    overflow: hidden;
    width: 80vw;
    height: 80vh;

    .mat-horizontal-stepper-wrapper {
      height: 100%;
      .mat-horizontal-stepper-content {
        // height: 100%;
      }
    }
    .mat-horizontal-stepper-header-container {
      display: none !important;
    }
    .mat-horizontal-content-container {
      padding: 0;
    }
  }
}



