body {
  //Loader Animation CSS

.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #F26666;
  left: 20%;
  transform-origin: 50%;
  animation: circle .5s alternate infinite ease;

  &:nth-child(2) {
    left: 45%;
    animation-delay: .2s;
  }

  &:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
  }
}

.loader-container {
  width: 150px;
  height: 60px;
  margin: 0;

  & .circle {
    width: 20px !important;
    height: 20px !important;
    position: absolute;
    border-radius: 50%;
    background-color: #F26666;
    left: 20%;
    transform-origin: 50%;
    animation: circle .5s alternate infinite ease;

    &:nth-child(2) {
      left: 45%;
      animation-delay: .2s;
    }

    &:nth-child(3) {
      left: auto;
      right: 15%;
      animation-delay: .3s;
    }
  }

  & .shadow {
    width: 20px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(186, 185, 189, 0.7);
    position: absolute;
    top: 76px;
    transform-origin: 50%;
    z-index: -1;
    left: 20%;
    filter: blur(1px);
    animation: shadow .5s alternate infinite ease;

    &:nth-child(4) {
      left: 45%;
      animation-delay: .2s
    }

    &:nth-child(5) {
      left: auto;
      right: 15%;
      animation-delay: .3s;
    }
  }
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: .7;
  }

  100% {
    transform: scaleX(.2);
    opacity: .4;
  }
}
}
