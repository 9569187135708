%input-styles {
  border: 1px solid $gray-3;
  border-radius: $radius-8;
  padding: 0.625rem 0.75rem;
  color: $gray-9;
  max-width: 100%;
  font-size: $size-14;
  font-family: $font-primary;
  height: $size-40;
  overflow: hidden;
  line-height: 1.3;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  margin: 0;
}
body {
  .mat-datepicker-content {
    .mat-calendar {
      height: auto;
    }
  }
}

body {
  .input-styles {
    @extend %input-styles;
  }
  .mat-mdc-checkbox-touch-target {
    display: none;
  }

  .icon-dropdown {
    .ng-select-container {
      // .ng-value-container {
      //     height: auto !important;
      //     height: auto !important;
      // }

      .ng-value {
        @include flex-combinator(row, nowrap, center, flex-start);

        .badge {
          padding: 0 !important;
          background-color: transparent;
          margin-right: 0.5rem;

          &:before {
            content: "";
            @include rounded(1rem, 0rem);
            background-size: contain;
            background-color: transparent;
          }
        }
      }

      // .ng-arrow-wrapper {
      //     position: initial;
      //     align-self: center !important;
      // }

      // &:after {
      //     display: none;
      // }
    }

    &.hide-input {
      .ng-input {
        display: none;
      }
    }
  }

  .ng-dropdown-panel {
    border-radius: 0.57rem;

    &.ng-select-bottom {
      top: 100%;
      box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.3);
    }

    &.ng-select-top {
      bottom: 100%;
      box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.3);
    }
    %ng-option-styles {
      padding: $size-8;
      padding-right: $size-24;
      font-family: $font-primary;
      color: $gray-9;
      line-height: 1.43;
      min-height: $size-32;
      display: flex !important;
      @include flex-combinator(row, nowrap, center, flex-start);
    }

    .ng-dropdown-header {
      @extend %ng-option-styles;
      label, .title {
        cursor: pointer;
      }
    }

    .ng-dropdown-panel-items {

      .ng-option,
      .ng-optgroup {
        @extend %ng-option-styles;
        label, .title {
          cursor: pointer;
        }

        .ng-option-label,
        .item-title {
          display: block;
          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;
        }

        span {
          &.badge {
            padding: 0 !important;
            margin-right: 0.571rem;

            &:before {
              background-size: 1rem 1rem;
              @include rounded(2rem, 0.428rem);
            }
          }
        }

        &.ng-option-marked {
          background-color: $primary-lighter;
          color: $gray-7;
        }

        &.ng-option-selected {
          background-color: $gray-50;
          color: $gray-9;
          // padding-right: $size-36;

          &:after {
            content: "\f00c";
            @include sequre($size-14);
            right: $size-4;
            position: absolute;
            font-family: 'Font Awesome 6 Pro' !important;
            font-size: $size-14;
          }

          &:hover {
            background-color: $primary-lighter;
          }
        }

        &.ng-option-child {
          padding-left: $size-28;
        }
      }
    }

    .scrollable-content {
      @include flex-combinator(column, nowrap, initial, initial);
    }
  }

  ng-select.popup-top {
    .ng-dropdown-panel {
      top: initial !important;
      bottom: 100%;
    }
  }

  .checkbox-select-option {
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {

        .ng-option,
        .ng-optgroup {
          &.ng-option-selected {
            padding-right: $size-8;

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  .mat-mdc-checkbox {
    .mdc-form-field {
      color: $black;
    }
  }

  .mat-radio-label {
    margin: 0;
  }

  mat-slide-toggle,
  .mat-slide-toggle,
  .mat-mdc-slide-toggle {
    .mat-slide-toggle-label {
      margin: 0;
    }

    .mat-slide-toggle-bar {
      height: 1.25rem;
      border-radius: 1.25rem;
      background-color: $light-gray-1;
    }

    .mat-slide-toggle-thumb-container {
      @include circle($size-16);
      top: $size-2;
      left: $size-2;

      .mat-slide-toggle-thumb {
        @include circle($size-16);
        top: $size-2;
        left: $size-2;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06), 0 1px 3px 0 rgba(16, 24, 40, 0.1);
      }
    }

    &.mat-checked {
      .mat-slide-toggle-bar {
        background-color: $primary;
      }

      .mat-slide-toggle-thumb {
        background-color: $white;
      }
    }

    .mat-slide-toggle-ripple {
      display: none;
    }

    &.list-item-link {
      label {
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
    }
  }
  mat-slide-toggle {
    .mdc-switch {

      .mdc-switch__icons,
      .mdc-switch__shadow,
      .mdc-switch__ripple {
        display: none;
      }

      .mdc-switch__track {
        height: $size-20;
        border-radius: $size-20;

        &:before {
          background: $gray-lighter;
        }

        &:after {
          background: $primary;
        }
      }

      &:enabled,
      &:enabled:hover:not(:focus):not(:active),
      &:enabled:hover:not(:focus):not(:active),
      &:enabled:focus:not(:active),
      &:enabled:active {
        .mdc-switch__track {
          &:after {
            background: $primary;
          }
        }
      }


      .mdc-switch__handle {
        @include circle($size-16);
        left: $size-2;

        &:before {
          background: $white;
        }
      }

      &.mdc-switch--unselected:enabled,
      &.mdc-switch--unselected:enabled:active,
      &.mdc-switch--unselected:enabled:focus:not(:active) {
        .mdc-switch__handle::after {
          background: $white;
        }
      }

      &.mdc-switch--selected:enabled:active,
      &.mdc-switch--unselected:enabled:hover:not(:focus):not(:active) {
        .mdc-switch__handle::after {
          background: $primary;
        }
      }

      &.mdc-switch--selected:enabled {
        .mdc-switch__handle::after {
          background: $white;
        }
      }

      &.mdc-switch--selected:enabled {

        &:hover:not(:focus):not(:active),
        &:focus:not(:active) {
          .mdc-switch__handle::after {
            background-color: $white;
          }
        }
      }
    }
    &.mat-warn {
      .mdc-switch {
        .mdc-switch__track {
          &:after {
            background: $warning-dark;
          }
        }

        &:enabled,
        &:enabled:hover:not(:focus):not(:active),
        &:enabled:hover:not(:focus):not(:active),
        &:enabled:focus:not(:active),
        &:enabled:active {
          .mdc-switch__track {
            &:after {
              background: $warning-dark;
            }
          }
        }


      &.mdc-switch--selected:enabled:active,
      &.mdc-switch--unselected:enabled:hover:not(:focus):not(:active) {
        .mdc-switch__handle::after {
          background: $warning-dark;
        }
      }
      }
    }
  }


  .error-msg {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    font-weight: $regular;
    font-size: 0.75rem;
  }

  //Mat Select
  .mat-select,
  .mat-mdc-select {
    &.theme-select {
      border: 1px solid $light-gray-1;
      padding: 0.5rem 0.9375rem;
      border-radius: $radius-4;
      font-size: $size-14;
      color: $gray-dark-3;

      .mat-select-trigger,
      .mat-mdc-select-trigger {
        height: auto;
      }
    }
    &.badge {
      padding-left: $size-10 !important;
      padding-right: 0 !important;

      .mat-mdc-select-trigger {
        padding: 0 $size-10 0 0 !important;
        border-width: 0 !important;
        height: auto !important;

        .mat-mdc-select-arrow-wrapper {
          position: initial !important;
          height: 10px;
          margin-left: $size-8;
        }
      }
    }
  }

  .mat-select-placeholder,
  .mat-mdc-select-placeholder {
    color: $gray-7;
  }

  .mat-select-panel-wrap {
    transform: translate(0, $size-40) !important;
  }

  .mat-select-panel,
  div.mat-mdc-select-panel {
    min-width: 100%;
    padding: 0;

    .mat-optgroup-label,
    .mat-option,
    mat-option {
      font-family: $font-primary;
      color: $gray-9;
      min-height: 42px;
      font-size: $size-14;

      mat-pseudo-checkbox {
        display: none;
      }

      .mdc-list-item__primary-text {
        @extend .text-ellipse;
      }


      &:hover:not(.mat-option-disabled),
      &:hover:not(.mdc-list-item--disabled) {
        background-color: $primary-lighter;
        color: $gray-5;

        .mdc-list-item__primary-text {
          color: $black !important;
        }
      }

      &.mat-selected:not(.mat-option-multiple),
      &.mat-selected:not(.mat-mdc-option-multiple),
      &.mat-active,
      &.mat-mdc-option-active {
        background-color: $primary-medium !important;
        color: $gray-9;
        padding-right: $size-36;

        .mdc-list-item__primary-text {
          color: $black !important;
          margin: 0;
        }

        &:after {
          content: "\f00c";
          @include sequre($size-20);
          right: $size-10;
          position: absolute;
          font-family: 'Font Awesome 6 Pro' !important;
          font-size: $size-16;
        }

        &:hover {
          background-color: $primary-lighter;
        }
      }

      &.mat-option-disabled,
      &.mdc-list-item--disabled {
        color: $gray-5;
      }
    }
  }

  //Mat Radio button
  .mat-radio-button,
  .mat-mdc-radio-button {
    font-family: $font-primary;
    font-weight: $medium;
    color: $gray-9;

    .mdc-form-field {
      gap: $size-8;

      .mdc-label {
        padding-left: 0;
      }
    }

    .mdc-radio {
      @include flexbox;
      padding: 0;
      align-self: flex-start;

      .mat-ripple,
      mat-ripple {
        display: none;
      }

      .mdc-radio__native-control {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }

    .mat-radio-ripple,
    .mat-mdc-radio-touch-target {
      display: none;
    }

    .mat-radio-label {
      @include flex-combinator(row, nowrap, flex-start, flex-start);

      .mat-radio-container {
        @include circle($size-20);

        .mat-radio-outer-circle {
          border: $size-1 solid $gray-3;
        }
      }
    }

    .mdc-radio__background {
      &:before {
        display: none !important;
      }

      .mdc-radio__outer-circle {
        border: $size-1 solid $gray-3 !important;
      }

      .mdc-radio__inner-circle {
        border-width: 0;
        @include circle($size-6);
        left: calc(50% - 3px);
        top: calc(50% - 3px);
        background: $primary;
      }
    }

    &.mat-radio-checked {
      .mat-radio-label {
        .mat-radio-container {
          .mat-radio-outer-circle {
            border-color: $primary;
            background-color: $primary-1;
          }

          .mat-radio-inner-circle {
            transform: scale(0.3);
            background-color: $primary;
          }
        }
      }
    }

    .mat-radio-label-content,
    .mdc-label {
      font-size: $size-14;
      font-weight: $regular;
      color: $black;
      font-family: $font-primary;
      @include flex-combinator(column, nowrap, flex-start, flex-start);

      span {
        font-weight: $regular;
      }
    }

    &.text-ellipse {
      @include flexbox;
      width: 100%;
      overflow: hidden;

      .mat-radio-label {
        @include flex-combinator(row, nowrap, center, flex-start);

        .mat-radio-label-content,
        .mdc-label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .mdc-form-field {
        width: 100%;
        overflow: hidden;
          .mdc-label {
            flex: 1;
            overflow: hidden;
          }
      }
      .button-content-wraper {
        @include flex-combinator(row, nowrap, center);
        width: 100%;
        overflow: hidden;
        gap: $size-4;

        .label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

        }
      }
    }

    .mdc-radio,
    .mdc-radio:hover,
    .mdc-radio:focus {
      .mdc-radio__native-control:enabled:checked+.mdc-radio__background {
        .mdc-radio__outer-circle {
          border-color: $primary !important;
          background-color: $primary-1;
        }

        .mdc-radio__inner-circle {
          transform: scale(1);
        }

      }
    }
  }

  mat-radio-group,
  .mat-radio-group {
    &.horizontal-items {
      @include flex-combinator(row, wrap);
      gap: $size-20;
      column-gap: $size-20;
    }

    &.vertical-items {
      @include flex-combinator(column, nowrap);
      gap: $size-8;
    }
  }

  .checkbox-group {
    @include flex-combinator(column, nowrap);
    gap: $size-8;
  }

  //Mat Checkbox
  .mat-checkbox,
  .mat-mdc-checkbox {
    font-family: $font-primary;
    color: $gray-9;
    vertical-align: middle;

    &.label-none {

      .mat-checkbox-layout,
      .mdc-form-field {
        gap: 0;

        .mat-checkbox-label,
        .mdc-label {
          display: none;
        }
      }
    }

    &.text-ellipse {

      .mat-checkbox-label,
      .mdc-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .mat-checkbox-layout,
    .mdc-form-field {
      @include flex-combinator(row, nowrap, center);
      gap: $size-8;
      width: 100%;
      margin: 0;
    }

    .mat-checkbox-ripple,
    .mdc-checkbox__ripple,
    .mat-mdc-checkbox-ripple {
      display: none;
    }

    .mat-checkbox-inner-container,
    .mdc-checkbox {
      @include rounded($size-20, $radius-6);
      background-color: $white;
      overflow: initial;
      margin: 0;
      padding: 0;

      .mdc-checkbox__native-control {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      .mat-checkbox-frame {
        display: none;
      }
    }

    .mat-checkbox-label,
    .mdc-label {
      flex: 1;
      overflow: hidden;
      padding: 0;
    }

    &.mat-checkbox-disabled {
      .mat-checkbox-label {
        color: $gray-8;
      }
    }

    .mat-checkbox-background,
    .mdc-checkbox__background {
      border: $size-1 solid $gray-3 !important;
      border-radius: $radius-6;
      @include sequre($size-20);
      position: initial;
    }

    &.mat-checkbox-checked,
    &.mat-mdc-checkbox-checked {

      .mat-checkbox-inner-container,
      .mdc-checkbox {
        position: relative;

        .mat-checkbox-background,
        .mdc-checkbox__background {

          .mat-checkbox-checkmark,
          svg {
            display: none;
          }
        }

        .mat-checkbox-background,
        .mdc-checkbox__background {

          &:after {
            border-radius: $radius-6;
            background-color: $primary-1;
            border: $size-1 solid $primary;
            @include sequre($size-20);
            top: 0;
            left: 0;
            position: absolute;
            content: "\f00c";
            font-family: 'Font Awesome 6 Pro' !important;
            @include flex-combinator(row, nowrap, center, center);
          }
        }
      }
    }

    &.mat-checkbox-disabled,
    &.mdc-checkbox--disabled {

      .mat-checkbox-inner-container,
      .mdc-checkbox,
      .mdc-checkbox__background {
        background-color: $gray-1;
      }
    }
  }

  mat-form-field,
  .mat-form-field,
  .mat-mdc-form-field,
  .form-item {
    max-width: 100%;
    font-family: $font-primary;
    position: relative;

    &.label-none {

      .mat-form-field-label-wrapper,
      .mat-mdc-floating-label {
        display: none !important;
      }
    }



    &.fixed-label {
      margin: 0;

      .mat-form-field-wrapper,
      .mat-mdc-text-field-wrapper {
        padding: 0 !important;
        @include flexbox;
        background: transparent;
        width: 100%;
        overflow: visible;
      }

      .mat-form-field-infix,
      .mat-mdc-form-field-infix {
        @include flex-combinator(column, nowrap, initial, initial);
        padding: 0;
        border: 0;
        width: auto;
        min-height: auto;

        .mat-form-field-label-wrapper,
        .mat-mdc-floating-label {
          order: 1;
          position: initial;
          padding: 0;
          align-self: flex-start;
          pointer-events: initial;
          transform: translate(0, 0);
          margin-bottom: $size-8;

          .mat-form-field-label,
          mat-label {
            transform: none;
            width: initial;
            position: initial;
            color: $black;
            font-size: $size-14;
            font-weight: $medium;
            font-family: $font-inter;
            line-height: 1.3;
            pointer-events: initial;
            @include inline-flex;
            margin: 0;
            gap: $size-4;

            .mat-tooltip-trigger {
              transform: translateY(3px);

              img {
                max-width: $size-16;
                max-height: $size-16;
              }
            }
          }

          .mat-placeholder-required,
          .mat-mdc-form-field-required-marker {
            color: $error;

            &:after {
              color: $error;
            }
          }
        }

        .mat-select,
        .mat-mdc-select {
          order: 2;

          &.mat-select-disabled,
          &.mat-mdc-select-disabled {
            background-color: $gray-50;
            border-color: $gray-3;
            color: $gray-5;
          }
        }

        textarea.mat-input-element,
        textarea.mat-mdc-input-element {
          min-height: $size-40;
          height: auto;
          //padding-top: 5px;
          padding-bottom: 0;
        }

        .mat-select-trigger,
        .mat-mdc-select-trigger,
        .mat-input-element,
        .mat-mdc-input-element {
          order: 2;
          @extend %input-styles;

          &::placeholder {
            color: $gray-7;
          }

          &[disabled] {
            background-color: $gray-50;
            border-color: $gray-3;
            color: $gray-5;
          }

          .mat-select-arrow-wrapper,
          .mat-mdc-select-arrow-wrapper {
            position: absolute;
            right: 15px;
            transform: translate(0);

            &:after {
              content: "\f106";
              font-family: 'Font Awesome 6 Pro' !important;
              @include sequre(12px);
              transform: rotate(180deg);
            }

            .mat-select-arrow,
            .mat-mdc-select-arrow {
              display: none;
            }
          }

          .mat-select-value,
          .mat-mdc-select-value {
            color: $gray-9;
            text-align: left;
          }

          &[readonly="true"] {
            background-color: $gray-50;
            border-color: $gray-3;
            color: $gray-5;
          }
        }

        .mat-select-trigger,
        .mat-mdc-select-trigger {
          padding-right: $size-36;
        }

        .input-group-items {
          order: 2;
          @include flex-combinator(row, nowrap, initial, initial);

          .input-field-first {
            position: absolute;
            left: 0;
            width: 72px;
            text-align: right;
            border-width: 0 1px 0 0;
            border-radius: 0;
            color: $gray-6;
            padding-right: 0.75rem !important;
          }

          .input-field-second {
            padding-left: 85px;
            padding-right: 92px;
          }

          .input-field-third {
            position: absolute;
            padding: 0 !important;
            left: 117px;
            border: 0;
            color: $gray-6;
          }
        }
      }

      .error-message {
        @include flex-combinator(row, nowrap, flex-start);
        color: $error;
        font-size: 11.5px;
        position: absolute;
        // bottom: -$size-20;
        top: calc(100% + -2px);
        left: 0;
        width: 100%;
        gap: $size-4;

        img {
          max-width: $size-12;
          max-height: $size-12;
        }
      }

      &.mat-focused.error,
      &.error {

        .mat-form-field-infix,
        .mat-mdc-form-field-infix {

          .mat-select,
          .mat-input-element,
          .mat-mdc-input-element {
            border-color: $error-3;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba($error-1, 0.5);
          }
        }
      }

      &.mat-form-field-can-float.mat-form-field-should-float,
      &.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float {

        .mat-form-field-label-wrapper,
        .mat-mdc-floating-label {

          .mat-form-field-label,
          .mat-mdc-floating-label {
            transform: none !important;
            color: $gray-7 !important;
            font-size: $size-14 !important;
            font-weight: $medium !important;
            font-family: $font-inter !important;
          }
        }
      }

      .mat-form-field-underline,
      .mdc-line-ripple {
        display: none;
      }

      .mat-form-field-subscript-wrapper,
      .mat-mdc-form-field-subscript-wrapper {
        position: absolute;
        bottom: -$size-20;
        top: initial;
        margin: 0;
        font-size: $size-12;
        font-family: $font-primary;
      }

      &.mat-form-field-invalid,
      &.mat-mdc-form-field-invalid {

        .mat-form-field-infix,
        .mat-mdc-form-field-infix {

          .mat-select-trigger,
          .mat-mdc-select-trigger,
          .mat-input-element,
          .mat-mdc-input-element {
            border-color: $error-3;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba($error-1, 0.5);
          }
        }

        .mat-form-field-prefix,
        .mat-form-field-suffix,
        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-icon-suffix {
          &.border-right {
            border-color: $error-3;
          }

          &.border-left {
            border-color: $error-3;
          }
          .matprefix,
          .matsuffix {
            > img {
              pointer-events: none;
            }
          }
        }
      }

      .mat-form-field-outline-thick,
      .mat-mdc-form-field-outline-thick {
        display: none;
      }

      .mat-datepicker-toggle {

        .mat-icon-button,
        .mdc-icon-button {
          padding: 0;
          @include sequre(100%);
          min-width: auto;
          border-radius: 0;
          border-left: $size-1 solid $gray-3;
          @include flex-combinator(row, nowrap, center, center);

          .mat-button-wrapper,
          .mat-datepicker-toggle-default-icon {
            display: none;
          }

          &:after {
            position: absolute;
            top: 50%;
            left: 0;
            content: "\f333";
            font-family: 'Font Awesome 6 Pro' !important;
            @include sequre(100%);
            z-index: 1;
            font-size: $size-20;
            color: $dark;
          }
        }
      }

      &[class*="icon-form-"],
      &[class*="icon-field-"] {

        .mat-form-field-prefix,
        .mat-form-field-suffix,
        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-icon-suffix {
          position: absolute;
          bottom: 1px;
          @include sequre(calc(2.5rem - 2px));
          @include flex-combinator(row, nowrap, center, center);
          overflow: hidden;
          z-index: 2;

          .mat-icon {
            font-size: 140%;
            line-height: 1;
          }

          button,
          .mat-datepicker-toggle {
            padding: 0;
            @include sequre(39px);
            min-width: auto;
            border-radius: 0;
            border-width: 0;
            background: transparent;
            box-shadow: none;

            &:hover,
            &:focus {
              background-color: $gray-2;
            }
          }

          &.border-right {
            border-right: 1px solid $gray-3;
          }

          &.border-left {
            border-left: 1px solid $gray-3;
          }
        }

        &[class*="before"],
        &[class*="left"] {

          .mat-form-field-infix,
          .mat-mdc-form-field-infix {

            .mat-input-element,
            .mat-mdc-input-element,
            .mat-mdc-select-trigger {
              padding-left: $size-36;
            }
          }

          .mat-form-field-prefix,
          .mat-mdc-form-field-icon-prefix {
            left: 1px;
            border-radius: $radius-8 0 0 $radius-8;
          }

          &.action-2 {

            .mat-form-field-infix,
            .mat-mdc-form-field-infix {

              .mat-input-element,
              .mat-mdc-input-element,
              .mat-mdc-select-trigger {
                padding-left: 5.625rem;
              }
            }
          }
        }

        &[class*="after"],
        &[class*="right"] {

          .mat-form-field-infix,
          .mat-mdc-form-field-infix {

            .mat-input-element,
            .mat-mdc-input-element,
            .mat-mdc-select-trigger {
              padding-right: 3.1rem;
            }
          }

          .mat-form-field-suffix,
          .mat-mdc-form-field-icon-suffix {
            right: 1px;
            border-radius: 0 $radius-8 $radius-8 0;
            background: $gray-50;
            padding-left: 0px;
          }

          &.action-2 {

            .mat-form-field-infix,
            .mat-mdc-form-field-infix {

              .mat-input-element,
              .mat-mdc-input-element,
              .mat-mdc-select-trigger {
                padding-right: 5.625rem;
              }
            }
          }
        }

        &.action-2 {

          .mat-form-field-prefix,
          .mat-form-field-suffix,
          .mat-mdc-form-field-icon-prefix,
          .mat-mdc-form-field-icon-suffix {
            width: 79px;
          }

          .actions {
            @include flex-combinator(row, nowrap, center, initial);

            button {
              &.mat-success {
                color: $success;

                &:hover,
                &:focus {
                  background-color: $success-1;
                }
              }

              &.mat-cancel,
              &.mat-error {
                color: $error;

                &:hover,
                &:focus {
                  background-color: $error-1;
                }
              }
            }
          }
        }
      }

      &.mat-form-field-appearance-legacy.mat-focused,
      &.mat-mdc-form-field-appearance-legacy.mat-focused {

        .mat-form-field-label,
        .mat-mdc-floating-label {
          color: $gray-7 !important;
          font-size: $size-14 !important;
          font-weight: $medium !important;
          transform: none !important;
        }
      }

      &.mat-focused {

        .mat-form-field-infix,
        .mat-mdc-form-field-infix {

          .mat-select-trigger,
          .mat-mdc-select-trigger,
          .mat-input-element,
          .mat-mdc-input-element {
            border-color: $primary-3;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $primary-lighter;
          }
        }
      }
    }

    &.disabled {
      pointer-events: none;

      .mat-form-field-infix,
      .mat-mdc-form-field-infix {

        .mat-select-trigger,
        .mat-mdc-select-trigger,
        .mat-input-element,
        .mat-mdc-input-element {
          pointer-events: none;
          background-color: $gray-50;
          border-color: $gray-3;
          color: $gray-5;
        }
      }
    }

    .mat-select-trigger,
    .mat-mdc-select-trigger,
    .mat-input-element,
    input {
      &.disabled {
        pointer-events: none;
        background-color: $gray-50;
        border-color: $gray-3;
        color: $gray-5;
      }
    }

    &.form-field-medium {
      &.fixed-label {

        .mat-form-field-infix,
        .mat-mdc-form-field-infix {

          .mat-select-trigger,
          .mat-mdc-select-trigger,
          .mat-input-element,
          .mat-mdc-input-element {
            height: $size-36;
            padding: 0 $size-8;
          }
        }

        &[class*="icon-form-"],
        &[class*="icon-field-"] {

          .mat-form-field-prefix,
          .mat-form-field-suffix,
          .mat-mdc-form-field-icon-prefix,
          .mat-mdc-form-field-icon-suffix {
            position: absolute;
            bottom: 1px;
            @include sequre(calc($size-36 - 2px));
            @include flex-combinator(row, nowrap, center, center);
            overflow: hidden;
            z-index: 2;

            button,
            .mat-datepicker-toggle {
              padding: 0;
              @include sequre($size-34);
            }

            &[class*="before"],
            &[class*="left"] {
              &:after {
                background-size: $size-16;
              }
            }
          }
        }

        &[class*="before"],
        &[class*="left"] {

          .mat-form-field-infix,
          .mat-mdc-form-field-infix {

            .mat-input-element,
            .mat-mdc-input-element {
              padding-left: $size-40;
            }
          }

          &.action-2 {

            .mat-form-field-infix,
            .mat-mdc-form-field-infix {

              .mat-input-element,
              .mat-mdc-input-element {
                padding-left: 72px;
              }
            }
          }
        }

        &[class*="after"],
        &[class*="right"] {

          .mat-form-field-infix,
          .mat-mdc-form-field-infix {

            .mat-input-element,
            .mat-mdc-input-element {
              padding-right: $size-40;
            }
          }

          &.action-2 {

            .mat-form-field-infix,
            .mat-mdc-form-field-infix {

              .mat-input-element,
              .mat-mdc-input-element {
                padding-right: 72px;
              }
            }
          }
        }

        &.action-2 {

          .mat-form-field-prefix,
          .mat-form-field-suffix,
          .mat-mdc-form-field-icon-prefix,
          .mat-mdc-form-field-icon-suffix {
            width: 68px;
          }
        }
      }
    }

    &.form-field-smaller {
      &.fixed-label {

        .mat-form-field-infix,
        .mat-mdc-form-field-infix {

          .mat-select-trigger,
          .mat-mdc-select-trigger,
          .mat-input-element,
          .mat-mdc-input-element {
            height: $size-30;
            padding: 0 $size-8;
          }
          .mat-select-trigger,
          .mat-mdc-select-trigger {
            padding-right: $size-32;
          }
        }

        &[class*="icon-form-"],
        &[class*="icon-field-"] {

          .mat-form-field-prefix,
          .mat-form-field-suffix,
          .mat-mdc-form-field-icon-prefix,
          .mat-mdc-form-field-icon-suffix {
            position: absolute;
            bottom: 1px;
            @include sequre(calc($size-30 - 2px));
            @include flex-combinator(row, nowrap, center, center);
            overflow: hidden;
            z-index: 2;

            button,
            .mat-datepicker-toggle {
              padding: 0;
              @include sequre($size-28);
            }

            &[class*="before"],
            &[class*="left"] {
              &:after {
                background-size: $size-14;
              }
            }
          }
        }

        &[class*="before"],
        &[class*="left"] {

          .mat-form-field-infix,
          .mat-mdc-form-field-infix {

            .mat-input-element,
            .mat-mdc-input-element {
              padding-left: $size-34;
            }
          }

          &.action-2 {

            .mat-form-field-infix,
            .mat-mdc-form-field-infix {

              .mat-input-element,
              .mat-mdc-input-element {
                padding-left: 60px;
              }
            }
          }
        }

        &[class*="after"],
        &[class*="right"] {

          .mat-form-field-infix,
          .mat-mdc-form-field-infix {

            .mat-input-element,
            .mat-mdc-input-element {
              padding-right: $size-34;
            }
          }

          &.action-2 {

            .mat-form-field-infix,
            .mat-mdc-form-field-infix {

              .mat-input-element,
              .mat-mdc-input-element {
                padding-right: 60px;
              }
            }
          }
        }

        &.action-2 {

          .mat-form-field-prefix,
          .mat-form-field-suffix,
          .mat-mdc-form-field-icon-prefix,
          .mat-mdc-form-field-icon-suffix {
            width: 56px;
          }
        }
      }
    }

    &.sign-form-field {

      .mat-form-field-infix,
      .mat-mdc-form-field-infix {

        textarea.mat-input-element,
        textarea.mat-mdc-input-element {
          border-width: 0 0 1px 0;
          border-radius: 0;
          box-shadow: none !important;
          font-family: $font-sign;
          font-size: $size-48;
          text-align: center;
        }
      }
    }
  }

  .row-editalbe {

    .mat-form-field,
    .mat-mdc-form-field {
      &.disabled {
        pointer-events: none;

        .mat-select-trigger,
        .mat-mdc-select-trigger,
        .mat-input-element,
        .mat-mdc-input-element {
          border-color: transparent;
          pointer-events: none;
          background-color: transparent;
          box-shadow: none;

          .mat-select-arrow-wrapper,
          .mat-mdc-select-arrow-wrapper {
            display: none;
          }
        }
      }

      .mat-select-trigger,
      .mat-mdc-select-trigger,
      .mat-input-element,
      .mat-mdc-input-element {
        &.disabled {
          border-color: transparent;
          pointer-events: none;
          background-color: transparent;
          box-shadow: none;

          .mat-select-arrow-wrapper,
          .mat-mdc-select-arrow-wrapper {
            display: none;
          }
        }
      }
    }

    .ng-form-field {
      &.disabled {
        pointer-events: disabled;

        .ng-select {
          pointer-events: none;

          .ng-value-container {
            pointer-events: none;
            border-color: transparent;
            background-color: transparent;
            box-shadow: none;
          }

          .ng-arrow-wrapper {
            display: none;
          }
        }
      }

      .ng-select {
        &.disabled {
          pointer-events: none;

          .ng-value-container {
            pointer-events: none;
            border-color: transparent;
            background-color: transparent;
            box-shadow: none;
          }
        }

        .ng-arrow-wrapper {
          display: none;
        }
      }
    }
  }

  //Ng form field
  .ng-select {
    .ng-option {
      font-family: $font-primary;
    }
  }

  .ng-form-field {
    &.input-none {
      .ng-input {
        display: none !important;
      }
    }

    &.required {
      >label {
        &:after {
          content: "*";
          color: $error;
        }
      }
    }

    label {
      color: $black;
      font-size: $size-14;
      font-weight: $regular;
      font-family: $font-inter;
      line-height: 1.3;
      margin-bottom: $size-8;
      @include flexbox;

      &.required {
        &:after {
          content: "*";
          color: $error;
        }
      }

      .required {
        color: $error;
      }
    }

    .ng-select {
      padding: 0;

      .ng-select-container {
        min-height: auto;
        position: relative;
        padding: 0;

        &:after {
          display: none;
        }

        .ng-value-container {
          position: relative;
          height: 40px;
          border: 1px solid $gray-3;
          border-radius: $radius-8;
          padding: 0.625rem 2.5rem 0.75rem 0.625rem;
          color: $gray-9;
          max-width: 100%;
          font-size: $size-14;
          font-weight: $regular;
          font-family: $font-primary;
          @include align-items(center);

          .ng-input {
            bottom: $size-10;

            input {
              // padding: 0 2.5rem 0 $size-10;
              padding: 0;
              padding-left: $size-10;
            }
          }

          .required-asterisk {
            display: none;
          }
        }

        .ng-arrow-wrapper {
          position: absolute;
          right: $size-8;
          bottom: 2px !important;
          height: calc(100% - 4px);
          width: $size-12;
          border: 0 !important;
          @include flex-combinator(row, nowrap, center, center);

          &:after {
            content: "\f106";
            font-family: 'Font Awesome 6 Pro' !important;
            @include sequre(12px);
            transform: rotate(180deg);
          }

          .ng-arrow {
            display: none;
          }
        }

        .ng-clear-wrapper {
          position: absolute;
          right: $size-24;
          bottom: 2px !important;
          height: calc(100% - 4px);
          width: $size-12;
          border: 0 !important;
          @include flex-combinator(row, nowrap, center, center);

          &:after {
            content: "\f00d";
            font-family: 'Font Awesome 6 Pro' !important;
            @include sequre(10px);
            transform: rotate(180deg);
          }

          .ng-clear {
            display: none;
          }
        }

        &.ng-has-value {
          .ng-placeholder {
            opacity: 0;
            display: none;
          }
        }

        .ng-placeholder {
          position: initial;
          font-size: $size-14;
          font-weight: $regular;
          transform: translate(0, 0) !important;
          color: $gray-7 !important;
          font-family: $font-primary;
        }
      }

      &.ng-select-focused {
        .ng-select-container {
          .ng-value-container {
            .ng-placeholder {
              display: none;
            }
          }
        }
      }

      &.ng-invalid.ng-touched {
        .ng-select-container {
          .ng-value-container {
            border-color: $error-3;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba($error-1, 0.5);
          }
        }
      }

      &.disabled,
      &[ng-reflect-is-disabled="true"] {
        pointer-events: none;

        .ng-value-container {
          pointer-events: none;
          background-color: $gray-50;
          border-color: $gray-3;

          .ng-input {
            display: none;
          }
        }
      }

      &.ng-select-disabled {
        .ng-select-container {
          .ng-value-container {
            background-color: $gray-50;
            border-color: $gray-3;
            color: $gray-5;

            .ng-input {
              display: none;
            }
          }
        }
      }
    }

    &.disabled {
      pointer-events: none;

      .ng-select {
        pointer-events: none;

        .ng-select-container {
          .ng-value-container {
            pointer-events: none;
            background-color: $gray-50;
            border-color: $gray-3;
            color: $gray-5;
          }
        }
      }
    }
  }

  .arrow-none {

    .ng-value-container,
    .ng-input input {
      padding-right: $size-20 !important;
    }

    .ng-arrow-wrapper {
      display: none !important;
    }
  }

  .field-inline {
    width: 100%;
    @include flex-combinator(row, nowrap, initial, initial);

    .mat-form-field,
    .mat-mdc-form-field {
      flex: 1;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }

  .ng-dropdown-panel {
    // min-width: 250px;
    border: $size-1 solid $gray-2;
    @extend .card-shadow;
    border-radius: $size-12;

    .ng-dropdown-panel-items {
      // height: initial;
      // max-height: 50vh;
      border-radius: $size-12;
    }
  }

  .mat-form-group,
  .form-group {
    @include flex-combinator(row, wrap, flex-end, flex-start);
    gap: $size-20;
    column-gap: $size-20;
    margin: 0;

    &[class*="row-item"] {

      .mat-form-item,
      .form-item {
        width: 100%;
      }

      &[class*="-2"] {

        .mat-form-item,
        .form-item {
          width: calc(50% - $size-10);
        }

        @media (max-width: 639px) {

          .mat-form-item,
          .form-item {
            width: 100%;
          }
        }
      }

      &[class*="-3"] {

        .mat-form-item,
        .form-item {
          width: calc(33.333% - 13.333px);
        }

        @media (max-width: 1199px) {

          .mat-form-item,
          .form-item {
            width: calc(50% - $size-10);
          }
        }

        @media (max-width: 639px) {

          .mat-form-item,
          .form-item {
            width: 100%;
          }
        }
      }

      &[class*="-4"] {

        .mat-form-item,
        .form-item {
          width: calc(25% - $size-15);
        }

        @media (max-width: 1365px) {

          .mat-form-item,
          .form-item {
            width: calc(33.333% - 13.333px);
          }
        }

        @media (max-width: 1199px) {

          .mat-form-item,
          .form-item {
            width: calc(50% - $size-10);
          }
        }

        @media (max-width: 639px) {

          .mat-form-item,
          .form-item {
            width: 100%;
          }
        }
      }
    }

    &.gap-8 {
      &[class*="row-item"] {
        &[class*="-2"] {

          .mat-form-item,
          .form-item {
            width: calc(50% - $size-4);
          }

          @media (max-width: 639px) {

            .mat-form-item,
            .form-item {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .file-upload {
    padding: $size-30 $size-16;
    width: 560px;
    max-width: 100%;
    @include flex-combinator(column, nowrap, center, center);
    border: $size-1 solid $gray-3;
    border-radius: $size-12;
    gap: $size-14;

    .upload-icon {
      @include circle($size-40);
      border: $size-6 solid $gray-50;
      background-color: $gray-1;
      @include flex-combinator(row, wrap, center, center);
    }

    p {
      font-size: $size-14;
    }
  }


  mat-progress-bar,
  .mat-progress-bar,
  .mat-mdc-progress-bar {
    border-radius: $size-4;
    height: $size-8 !important;

    .mdc-linear-progress__buffer-bar {
      background-color: $gray-2;
    }

    .mat-progress-bar-buffer,
    .mdc-linear-progress__buffer {
      background-color: $gray-2;
      height: $size-8;
    }

    .mdc-linear-progress__bar,
    .mdc-linear-progress__bar-inner {
      height: $size-8;
    }

    .mat-progress-bar-background {
      background-color: $gray-2;
      fill: $gray-2;
    }

    .mdc-linear-progress__bar-inner {
      border-top-width: $size-8;
      border-top-color: $primary-darker;
    }

    .mat-progress-bar-fill {
      fill: $primary-darker;

      &:after {
        background-color: $primary-darker;
      }
    }
  }

    .outline-error {
        outline: $size-1 solid $error !important;
    }

    tr.outline-error {
        &:last-child {
            &:after {
                content: '';
                border-bottom: $size-1 solid $error;
                width: calc(100% + 1px);
                height: $size-1;
                position: absolute;
                z-index: 99;
                opacity: 1;
                bottom: -1px;
                left: -1px;
            }
        }
    }

  .custom-checkbox {
    position: relative;
    cursor: pointer;
    @include inline-flex;
    gap: $size-8;

    .label-block {
      @include flex-combinator(row, nowrap, flex-start);
      cursor: pointer;
      gap: $size-8;
      color: $black;
    }

    .custom-checkbox-input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;

      &:disabled {
        cursor: initial;
      }
    }

    .check-box {
      background: transparent;
      border: $size-1 solid $gray-3;
      margin: 0;
      @include rounded($size-20, $size-6);
      min-width: $size-20;
      @include flex-combinator(row, nowrap, center, center);

      .check-icon,
      .fa-check {
        display: none;
      }
    }

    .custom-checkbox-input:checked+.check-box {
      background-color: $primary-1;
      border-color: $primary;

      .check-icon,
      .fa-check {
        @include inline-flex;
      }
    }

    .custom-checkbox-input:checked+.label-block {
      .check-box {
        background-color: $primary-1;
        border-color: $primary;

        .check-icon,
        .fa-check {
          @include inline-flex;
        }
      }
    }

    .custom-checkbox-input:disabled+.label-block {
      opacity: 0.4;
      cursor: auto;
    }

  }


  .form-view-only {

    .mat-form-field,
    .mat-mdc-form-field {
      &.fixed-label {
        pointer-events: none;

        .mat-form-field-infix,
        .mat-mdc-form-field-infix {

          mat-select,
          .mat-mdc-select {
            background: transparent !important;

            &.mat-mdc-select-disabled .mat-mdc-select-trigger {
              user-select: auto;
              -webkit-user-select: auto;
              cursor: auto;
            }
          }

          .mat-select-trigger,
          .mat-mdc-select-trigger,
          .mat-input-element,
          .mat-mdc-input-element {
            padding: 0 !important;
            border: 0;
            height: auto;
            box-shadow: none;
            background-color: transparent !important;
            border-radius: 0;
          }
        }

        .mat-select-arrow-wrapper,
        .mat-mdc-select-arrow-wrapper,
        .mat-placeholder-required,
        .mat-form-field-suffix,
        .mat-form-field-prefix,
        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-icon-prefix,
        .mat-tooltip-trigger {
          display: none !important;
        }
      }
    }

    .ng-select {
      .ng-select-container {
        .ng-value-container {
          height: auto;
          padding: 0;
          border: 0;
          box-shadow: none;
        }

        .ng-arrow-wrapper {
          display: none;
        }
      }

      .required,
      label.required {
        &:after {
          display: none;
        }
      }
    }
  }

  .hover-action {
    .hover-action-item {
      visibility: hidden;
      // display: none;
      transition: 0.3s;
    }

    &:hover {
      .hover-action-item {
        visibility: visible;
        @include inline-flex;
        transition: 0.3s;
      }
    }
  }

  .expandable-search {
    position: relative;

    mat-form-field {
      position: absolute;
      right: $size-48;
      top: 0;
      background-color: $white;
      width: 0px;
      transition: 0.3s;
      z-index: 2;
      overflow: hidden;
    }

    img {
      &.expand-img {
        display: block;
      }

      &.close-img {
        display: none;
      }
    }

    &.expanded {
      mat-form-field {
        width: 200px;
        min-width: 200px;
        transition: 0.3s;
      }

      img {
        &.expand-img {
          display: none;
        }

        &.close-img {
          display: block;
        }
      }
    }
  }

  .collapsable-search-block-below {
    display: none;
    width: 100%;

    &.expanded {
      display: flex;
    }
  }

  .editable-middle-input {
    position: relative;

    .mat-form-field.fixed-label,
    .mat-mdc-form-field.fixed-label {
      &.input-field-disabled-left {
        position: absolute;
        left: 0;
        width: 65px;
        bottom: 0;
        z-index: 1;

        .mat-form-field-infix,
        .mat-mdc-form-field-infix {

          .mat-input-element,
          .mat-mdc-input-element {
            border-radius: $size-8 0 0 $size-8;
            padding-right: 0;
          }
        }
      }

      &.input-field-editable {
        position: relative;
        z-index: 2;

        .mat-form-field-infix,
        .mat-mdc-form-field-infix {

          .mat-input-element,
          .mat-mdc-input-element {
            padding-left: 73px;
          }
        }
      }

      &.input-field-disabled-right {
        position: absolute;
        left: 120px;
        bottom: 0;
        z-index: 1;

        .mat-form-field-infix,
        .mat-mdc-form-field-infix {

          .mat-input-element,
          .mat-mdc-input-element {
            border-radius: 0;
            border-width: 0;
          }
        }
      }
    }
  }

  .checkbox-select-option {
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {

        .ng-option,
        .ng-optgroup {
          &.ng-option-selected {
            padding-right: $size-8;

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    >.mat-icon {
      padding: 0;
    }
  }

  .mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay {
    display: none;
  }
}

.custom-select-label {
    .mat-select-trigger,
    .mat-select-value {
        overflow: initial !important;
    }
    .mat-select-placeholder {
        font-weight: $semibold;
        color: $primary;
        margin-left: -24px;
        @include flex-combinator(row, nowrap);

        &:before {
            content: '\f08e';
            font-family: 'Font Awesome 6 Pro' !important;
            width: $size-16;
            height: $size-16;
            margin-right: $size-4;
            @include inline-flex;
            font-size: $size-20;
        }
    }
    &.error {
        .mat-form-field-infix {

            .mat-select,
            .mat-input-element {
                border: 0 !important;
                box-shadow: none !important;
            }
        }
        .mat-select-trigger {
            border: 0 !important;
            box-shadow: none !important;
        }
        .mat-select-placeholder {
            color: $error;
            &:before {
              position: absolute;
              top: 50%;
              left: 0;
              content: "\f35d";
              font-family: 'Font Awesome 6 Pro' !important;
              @include sequre(100%);
              z-index: 1;
              font-size: $size-20;
            }
        }
    }
}

.dropdown-icon-none {
    .mat-select-arrow-wrapper {
        display: none !important;
    }
}

.template-select {
  .ng-dropdown-panel {
    max-height: 50vh;
    .ng-dropdown-panel-items {
      max-height: 100%;
      .ng-option {
        min-height: $size-46;
      }
    }
  }
}

body {

  mat-chip-grid,
  .mat-mdc-chip-grid {
    border: $size-1 solid $gray-2;
    border-radius: $size-8;
    height: 40px;
    padding: 0 $size-8;
    @include flex-combinator(row, nowrap);

    .mdc-evolution-chip-set__chips {
      margin: 0;
      @include flex-combinator(row, nowrap, center, flex-start);
      gap: $size-8;
    }

    mat-chip-row,
    .mat-mdc-chip {
      margin: 0;
      height: 30px;
      border-radius: $size-8 !important;
    }
  }
}

.mat-mdc-form-field-focus-overlay {
  display: none;
}


body {
  .mat-mdc-radio-touch-target {
    display: none;
  }

  .mat-mdc-form-field-bottom-align::before {
    display: none;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding: 0;
  }

  .text-right,
  .text-end {

    .mat-mdc-input-element,
    .mat-input-element,
    input {
      text-align: right !important;
    }
  }
}


body {

  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: $primary !important;
    background-color: $primary-1 !important;

    &:hover,
    &:focus {
      background-color: $primary-1;
    }

    .mdc-checkbox__mixedmark {
      border-color: $primary;
      width: $size-10;
    }
  }
}

body {

  .mat-mdc-option.mdc-list-item--disabled .mat-mdc-option-pseudo-checkbox,
  .mat-mdc-option.mdc-list-item--disabled .mdc-list-item__primary-text,
  .mat-mdc-option.mdc-list-item--disabled>mat-icon {
    opacity: 1;
    color: $gray-5;
  }
}

body .mat-mdc-form-field.fixed-label.right-button-auto {
  .mat-mdc-form-field-icon-suffix {
    width: auto;

    button {
      width: auto;
      padding-left: $size-8;
      padding-right: $size-8;
      @extend .mat-primary;
    }
  }
}

body {
  mat-form-field,
  .mat-form-field,
  .mat-form-field.fixed-label,
  .mat-mdc-form-field,
  .mat-mdc-form-field.fixed-label {
    &.sign-form-field {
      .mat-form-field-infix,
      .mat-mdc-form-field-infix {
        textarea.mat-input-element,
        textarea.mat-mdc-input-element {
          border-width: 0 0 1px 0;
          border-radius: 0;
          box-shadow: none !important;
          font-family: $font-sign;
          font-size: $size-48;
          text-align: center;
        }
      }
    }
  }
}

body {
  .mat-mdc-button.mat-primary, body .mat-mdc-form-field.fixed-label.right-button-auto .mat-mdc-form-field-icon-suffix button.mat-mdc-button {
    color: $primary;
  }
}


body {
  .ng-form-field {
    &.selector-none {
      .ng-dropdown-panel {
        .ng-dropdown-panel-items {

          .ng-option,
          .ng-optgroup {
            &.ng-option-selected {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

input:disabled~* {
  opacity: 0.5;
  color: $gray-5;
  cursor: initial;
}
.cdk-overlay-container {
  .cdk-overlay-pane {
    .basis-for-calc-dropdown,
    .w-200 {
      width: 200px !important;
      min-width: 200px !important;
    }
  }
}

body {
  .mat-mdc-radio-button .mdc-radio--disabled+label {
    color: $gray-7;
  }
  .mdc-radio__native-control:disabled+.mdc-radio__background {
    opacity: 1;
  }
  .mat-mdc-radio-button .mdc-radio [aria-disabled=true] .mdc-radio__native-control:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle,
  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:disabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
    opacity: 0.8;
  }
}



body .chat-input-field {
    .mat-mdc-form-field.fixed-label {
      position: relative;
      padding: 0 $size-60 0 0;
      border: $size-1 solid $gray-3;
      border-radius: $size-8;
      width: 100%;
      .mat-mdc-text-field-wrapper {
        min-height: 50px;
        @include flex-combinator(column, nowrap, initial, center);
      }
      .mat-mdc-form-field-infix textarea.mat-mdc-input-element {
        overflow: auto;
        padding: 0 0 0 8px;
        min-height: initial;
        line-height: 1.5;
        border-width: 0;
        box-shadow: none !important;
      }
      &.mat-focused {
       border-color: $primary;
      }
    }
}

body {
  .mat-mdc-form-field.fixed-label.form-textarea {
    .mat-mdc-form-field-infix {
      padding: $size-10 0;
      border: 1px solid $gray-3;
      border-radius: $radius-8;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      textarea.mat-mdc-input-element {
        padding: 0 $size-12;
        border: 0;
        box-shadow: none;
      }
    }
  }
  .parent-div {
    .mat-mdc-form-field.fixed-label.form-textarea {
      .mat-mdc-form-field-infix {
        border: 0;
        box-shadow: none;
        textarea.mat-mdc-input-element {
          padding: 0;
          border: 0;
          box-shadow: none;
        }
      }
    }
  }
}


body {
  .mat-form-field-prefix,
  .mat-form-field-suffix,
  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    > img {
      pointer-events: none;
    }
    span {
      > img {
        pointer-events: none;
      }
    }
  }
}


body {
  .custom-tooltip-block {
    .custom-tooltip {
      visibility: hidden;
      transition: 0.3s;
      height: 0;
      display: none;
    }
    &:hover {
      .custom-tooltip {
        visibility: visible;
        @include inline-flex;
        height: auto;
        transition: 0.3s;
      }
    }
  }
}
