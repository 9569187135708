body .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  width: auto;
}
body {
  .mat-mdc-tab-body-wrapper {
    flex: 1;
  }
  .mat-mdc-tab {
    .mdc-tab__text-label {
      line-height: 1.43;
    }
  }
}
body {
  .mat-mdc-tab {
    &:hover {
      .mdc-tab__text-label {
        color: $primary;
      }
    }
  }
  .mat-mdc-tab.mdc-tab--active,
  .mat-mdc-tab.mdc-tab--active:focus,
  .mat-mdc-tab.mdc-tab--active:hover {
    .mdc-tab__text-label {
      color: $primary;
    }
  }
  .mdc-tab-indicator {
    .mdc-tab-indicator__content {
      border-top-width: 0;
      background: $primary;
      height: $size-4;
      border-radius: $size-4;
    }
  }
}

.mdc-tab__ripple,
.mat-mdc-tab-ripple {
  display: none !important;
}

.mat-tab-labels,
.mat-mdc-tab-labels {
    .mat-tab-label, .mat-tab-link {
        font-size: $size-14;
        font-weight: $medium;
        font-family: $font-primary;
        opacity: 1;
    }
}

.mat-ink-bar {
    height: 4px !important;
    border-radius: $radius-4;
}

mat-tab-group,
.mat-tab-group,
.mat-mdc-tab-group {
    &.items-horizontal {
        .mat-tab-labels,
        .mat-mdc-tab-labels {
            gap: $size-20;
        }
    }

    &.items-vertical {
        .mat-tab-label,
        .mdc-tab {
            &:not(:first-child) {
                margin-top: 0.2rem;
            }
        }

        .mat-tab-labels,
        .mat-mdc-tab-labels {
            @include flex-combinator(column, wrap, flex-start, flex-start);

            .mat-tab-label,
            .mdc-tab {
                padding: 0;
                @include align-items(flex-start);
                @include justify-content(flex-start);
                height: auto;
                opacity: 1;

                .mat-radio-label-content {
                    color: $black-6;
                }
            }
        }


    }

    &.header-border-0 {
        .mat-tab-header,
        .mat-mdc-tab-header {
            border: 0;
        }
    }


}

mat-tab-group,
.mat-tab-group,
.mat-mdc-tab-group {
  * {
    font-family: $font-primary;
  }
    &.countable-tabs {
        font-family: $font-primary;
        .mat-tab-header,
        .mat-mdc-tab-header {
            .mat-tab-label-container,
            .mat-mdc-tab-label-container {
                .mat-tab-label,
                .mdc-tab {
                    padding: $size-10;
                    min-width: auto;
                    height: auto;
                    color: $gray-5;
                    opacity: 1;
                    font-size: $size-14;
                    font-weight: $medium;
                    font-family: $font-primary;
                    flex-grow: initial;

                    &.mat-tab-label-active,
                    &.mdc-tab--active {
                        color: $primary-darker;
                    }
                }

                .mat-ink-bar {
                    height: $size-2;
                    background-color: $primary-darker;
                    border-radius: 0;
                }
                .mdc-tab-indicator {
                  .mdc-tab-indicator__content--underline {
                    border-top-width: $size-4;
                    border-color: $primary-darker;
                  }
                }

                .mdc-tab--active {
                  .mdc-tab__text-label {
                    color: $primary-darker;
                  }
                }
            }
        }

        .mat-tab-body-content {
overflow-y: auto;
overflow-x: hidden;
        }
    }
}

mat-tab-group,
.mat-tab-group,
.mat-mdc-tab-group {
    &.sticky-labels {
        .mat-tab-header,
        .mat-mdc-tab-header {
            background-color: $white;
            position: sticky;
            top: 0;
            z-index: 2;
            border-width: 0;
            padding-bottom: $size-20;

            .mat-tab-label-container,
            .mat-mdc-tab-label-container {
                border-bottom: $size-1 solid $gray-2;
            }
        }
    }
}


.template-block-main mat-tab-group.button-group-tabs,
.template-block-main .button-group-tabs.mat-tab-group,
.template-block-main .button-group-tabs.mat-mdc-tab-group,
.mat-tab-group.button-group-tabs,
.mat-mdc-tab-group.button-group-tabs {
    .mat-tab-header,
    .mat-mdc-tab-header {
        border: 0;
        margin-bottom: $size-24;
        .mat-tab-list,
        .mat-mdc-tab-list {
            .mat-tab-labels,
            .mat-mdc-tab-labels {
                @include flex-combinator(row, nowrap, center, start);
                border-collapse: collapse;
                .mat-tab-label,
                .mdc-tab {
                    border: $size-1 solid $gray-3;
                    color: $gray-7;
                    height: auto;
                    width: auto;
                    min-width: 100px;
                    min-height: $size-40;
                    padding: $size-8 $size-16;
                    opacity: 1;
                    background-color: $white;
                    @include flex-combinator(row, wrap, center, center);
                    flex-grow: initial;

                    &:not(:last-child) {
                        border-right-width: 0;
                    }

                    &:first-child {
                        border-radius: $size-8 0 0 $size-8;
                    }
                    &:last-child {
                        border-radius: 0 $size-8 $size-8 0;
                    }

                    &:hover {
                        color: $gray-8;
                    }

                    &.mat-tab-label-active,
                    &.mdc-tab--active {
                        color: $gray-8;
                        background-color: $gray-50;

                        .mdc-tab__text-label {
                          color: $gray-8;
                        }
                    }
                }
            }
            .mat-ink-bar,
            .mdc-tab-indicator {
                display: none !important;
            }
        }
    }
    mat-tab-body {
        padding-top: 0;
    }
    &.tabs-button-small {
        .mat-tab-header,
        .mat-mdc-tab-header {
            .mat-tab-labels,
            .mat-mdc-tab-labels {
                .mat-tab-label,
                .mdc-tab {
                    min-height: $size-30;
                    height: $size-30;
                    min-width: auto;
                    padding: 0 $size-12;
                }
            }
        }
    }
}


.tabs-group-outer {
    position: relative;
    .right-buttons {
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
        z-index: 2;
    }
    .mat-tab-header,
    .mat-mdc-tab-header {
        padding-left: $size-8;
        margin-bottom: $size-8 !important;
    }
    .table-countable {
        tr {
            th,td {
                height: $size-30;
            }
        }
    }
}

.right-panel {
  mat-tab-group {
    &.v-tabs {
      flex-direction: row;
      height: 100%;

      .mat-mdc-tab-body-wrapper {
        flex: 1;
      }

      > mat-tab-header {
        border-bottom: none;
        background-color: $gray-v14-color;
        width: 50px;

        > .mat-tab-header-pagination {
          display: none !important;
        }

        > .mat-tab-label-container, .mat-mdc-tab-label-container {
          .mat-tab-labels, .mat-mdc-tab-labels {
            flex-direction: column;
            margin-top: 50px;

            .mat-tab-label, .mdc-tab {
              padding: 15px;
              min-width: 0;
              @include flex-combinator(row, nowrap, center, center);

              &-active {
                background-color: $ntr-menu-active;
                position: relative;

                &:before {
                  position: absolute;
                  content: "";
                  width: 4px;
                  height: 100%;
                  background-color: $primary-color;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  z-index: 1;
                }
              }
            }
          }
        }
      }

      .mat-tab-body-wrapper {
        flex: 1 1 auto;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}

body {
  .authen-tabs.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: initial;
  }
}

