.user-in-main {
    @include flex-combinator(row, wrap, initial, initial);
    min-height: 100%;
    height: 100%;
    overflow: auto;
    background-color: $lighter-gray;

    .left-block {
        width: 50%;
        position: relative;
        max-height: 100vh;
        overflow: hidden;

        @media (max-width: 767px) {
            position: absolute;
            width: 100vw;
        }

        .back-to-website-btn {
            position: absolute;
            top: $size-32;
            left: $size-32;
            z-index: 2;

            @media (max-width: 767px) {
                top: $size-15;
                left: $size-15;
            }
        }

        .welcomeSlider {
            @include flexbox;
            height: 100vh;
        }

        .support-link {
            position: absolute;
            bottom: $size-15;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
        }
    }

    .right-block {
        width: 50%;
        overflow-x: auto;
        padding: $size-32 $size-15;
        @include flex-combinator(row, wrap, center, center);
        max-height: 100vh;
        overflow: auto;

        @media (max-width: 767px) {
            position: relative;
            padding: 5rem $size-15;
            width: 100%;
        }

        .content-block {
            width: 456px;
            max-width: 100%;
            margin: 0 auto;
            padding: $size-40 $size-36;
            position: relative;
            z-index: 9;
            @include flex-combinator(column, wrap, initial, initial);

            @media (max-width: 1199px) {
                padding: $size-36;
            }

            @media (max-width: 991px) {
                padding: $size-30;
            }

            @media (max-width: 767px) {
                padding: $size-30 $size-20;
            }

            @media (max-width: 640px) {
                padding: $size-15;
            }

            form {
                @include flex-combinator(column, nowrap, initial, initial);
            }

            @media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
                height: 100%;
                overflow: auto;
                flex-wrap: nowrap;
            }

            @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {

                    height: 100%;
                    overflow: auto;
                    flex-wrap: nowrap;


            }

        }

        .other-log-button {
            width: calc(50% - 8px) !important;
            border-radius: 5px !important;

            @media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
                width: 100% !important;
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2){
                width: 100% !important;
            }

        }

        .items-list {
            @include flex-combinator(row, wrap, flex-start, center);
            gap: $size-24;

            .mat-button {
                overflow: hidden;
            }

            &.bottom-nav {
                .bottom-nav-item {
                    &:not(:last-child) {
                        margin-right: $size-40;

                        @media (max-width: 1199px) {
                            margin-right: $size-30;
                        }

                        @media (max-width: 991px) {
                            margin-right: $size-24;
                        }

                        @media (max-width: 767px) {
                            margin-right: $size-20;
                        }
                    }
                }
            }
        }
    }
}

.icon-outer {
    @include flex-combinator(row, wrap, center, center);
    @include inline-flex;
    @include circle($size-40);
}


.slider_container {
  position: relative;
  width: 100%;
  height: 100%;
  @include flex-combinator(column, nowrap, center, flex-start);
  overflow: hidden;
  .slider {
    position: relative;
    width: 100%;
    height: 100%;
    @include flex-combinator(row, nowrap, center, space-between);
    animation: 8s cubic-bezier(1, 0.95, 0.565, 1) sliding infinite;
    .slide {
      position: relative;
      min-width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@keyframes sliding {
  0% {
    transform: translateX(0%);
  }
  40% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
