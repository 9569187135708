figure {
    margin: 0;
    svg {
        font-family: $font-primary !important;
    }
}

.highcharts-title,
.highcharts-legend-item,
.highcharts-label {
    font-family: $font-primary !important;
    color: $base-color !important;
    text {
        color: $base-color !important;
        &[style*="fill: rgb(0, 0, 0)"] {
            fill: $base-color !important;
        }
    }
}

.highcharts-credits,
.highcharts-background,
.highcharts-plot-border,
.highcharts-plot-background {
    display: none !important;
}
