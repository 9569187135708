.toast-container {
    &[style*="position"] {
        z-index: 1;
    }

    position: fixed;

    &.toast-top-right {
        z-index: 9999999;
    }

    .ngx-toastr {
        border-radius: $size-12;
        padding: $size-16;
        gap: $size-8;
        border: $size-1 solid $gray-3;
        background-color: $gray-50;
        color: $gray-8;
        margin: 0;
        position: relative;
        word-break: normal;
        @include flex-combinator(column, wrap);
        @extend .card-shadow;
        background-image: none;
        cursor: initial !important;

        // .toast-message {
        //     word-break: break-all;
        // }

        &:before {
            content: "\f05a";
            font-family: 'Font Awesome 6 Pro' !important;
            @include sequre($size-16);
            background-image: none !important;
        }

        &:hover {
            @extend .card-shadow;
        }

        &.toast-success {
            background-color: $success-lighter;
            color: $success-darker;
            border-color: $success-light;

            .title {
                color: $success-darker;
            }

            p {
                color: $success-darker;
            }

            &:before {
                content: "\f058";
                font-family: 'Font Awesome 6 Pro' !important;
                @include circle(16px);
                @include flex-combinator(row, nowrap, center, center);
                font-size: $size-12;
                color: $success-darker;
            }
        }

        &.toast-primary {
            background-color: $secondary-light;
            color: $primary-darker;
            border-color: $primary-3;

            .title {
                color: $primary-darker;
            }

            p {
                color: $primary-darker;
            }

            &:before {
                content: "\f05a";
                font-family: 'Font Awesome 6 Pro' !important;
            }
        }

        &.toast-warning {
            background-color: $warning-lighter;
            color: $warning-darker;
            border-color: $warning-light;

            .title {
                color: $warning-darker;
            }

            p {
                color: $warning-darker;
            }

            &:before {
                content: "\f071";
                font-family: 'Font Awesome 6 Pro' !important;
            }
        }

        &.toast-alert,
        &.toast-error {
            background-color: $error-lighter;
            color: $error-darker;
            border-color: $error-light;

            .title {
                color: $error-darker;
            }

            p {
                color: $error-darker;
            }

            &:before {
                content: "\f06a";
                font-family: 'Font Awesome 6 Pro' !important;
            }
        }

        &.icon-none {
            &::before {
                display: none;
            }
        }
    }

    .close {
        position: absolute;
        right: $size-16;
        top: $size-16;
        z-index: 1;

        &:hover {
            color: $error;
        }
    }

    &.static-toast,
    &.position-static,
    &.toast-static {
        position: static;
        z-index: initial;
        width: 100%;

        .ngx-toastr {
            width: 100%;
            cursor: auto;
        }
    }
}
