body {
  mat-list,
  .mat-list,
  .mat-mdc-list {
      &.mat-list-base {
          padding: 0;

          .mat-list-item,
          .mat-mdc-list-item,
          .mat-list-option {
              height: auto;
              font-family: $font-primary;
              font-size: $size-14;

              .mat-list-item-content {
                  padding: 0;
                  width: 100%;
              }
          }
      }
  }
}
body {
  .mat-mdc-dialog-container {
    .mdc-dialog__content {
      font-family: $font-primary;
      font-size: $size-14;
      line-height: 1.3;
    }
  }
}

body {
  mat-list,
  .mat-list,
  .mat-list-base,
  .mat-mdc-list {
      padding: 0;

      &.list-items {
          padding-top: 0;

          .mat-list-item,
          .mat-mdc-list-item {
              height: auto;
          }
      }

      &.list-disc {

          .mat-list-item,
          .mat-mdc-list-item {
              color: $gray-6;
              @include flexbox;
              position: relative;

              &:before {
                  content: '';
                  @include circle(7px);
                  transform: translateY(6px);
                  background-color: $gray-6;
              }

              .mat-list-item-content {
                  padding: 0 0 0 0.5rem;
                  flex: 1;
              }
          }
      }

      mat-list-item,
      .mat-list-item,
      .mat-mdc-list-item,
      .mat-list-option {
          height: auto;
          @include inline-flex;
          font-family: $font-primary;
          padding: 0;

          .mat-list-item-content {
              padding: 0;
          }
      }
  }
}

ul,
ol {
    &.list-style-disc {
        list-style: disc;

        li {
          list-style-type: disc !important;
        }
    }

    &.list-style-lower-alpha {
        list-style: lower-alpha;

        li {
          list-style-type: lower-alpha !important;
        }
    }

    &.list-style-lower-roman {
        list-style: lower-roman;

        li {
          list-style-type: lower-roman !important;
        }
    }

    &.list-style-none {
        padding: 0;
        margin: 0;
        list-style: none;
    }
}

.mat-menu-panel,
.mat-mdc-menu-panel {

    .mat-menu-content,
    .mat-menu-content:not(:empty),
    .mat-mdc-menu-content,
    .mat-mdc-menu-content:not(:empty) {
        width: 100%;
        padding: 0;
    }
}

.detail-list-group {
    @include flex-combinator(row, wrap, flex-start);
    gap: $size-32;

    .detail-list-item {
        @include flex-combinator(column, wrap);
        gap: $size-8;
        max-width: 200px;
    }
}

.mat-list,
.mat-mdc-list {
    &.user-list {
        @include flex-combinator(row, wrap, flex-start);
        gap: $size-24;

        .mat-list-item,
        .mat-mdc-list-item {
            @include inline-flex;
            width: auto;
        }

        .user-list-item {
            @include flex-combinator(row, nowrap, center);
            gap: $size-8;

            .item-content {
                @include flex-combinator(column, wrap);
            }
        }
    }
}



.left-sidebar {

    mat-list-item,
    .mdc-list-item {
        overflow: visible;

        .mdc-list-item__content {
            overflow: visible;
        }
    }
}


.components-list {
    .mat-tab-group {
        .mat-tab-body-wrapper {
            .mat-tab-body {
                padding: 0;
            }
        }
    }

    mat-list {
        @include flex-combinator(column, nowrap);
        gap: $size-16;
        padding: $size-15;
        .components-list-item {
            width: 100%;
        }

        .mat-radio-label {
            padding: $size-8;
            width: 100%;
            border-radius: $size-8;
            border: $size-1 solid $gray-1;
            @include flex-combinator(row, nowrap, center, flex-start);

            &:hover {
                border-color: $primary;
                @extend .card-shadow;
            }

            .mat-radio-label-content {
                width: 100%;
                gap: $size-4;
                @include flex-combinator(column, nowrap, flex-start, flex-start);
                .image-block {
                    width: 100%;
                }
            }
        }
    }
}


.user-list-item {
    @include flex-combinator(row, nowrap, flex-start);
    gap: $size-8;
    font-size: $size-14;
    width: 100%;

    .item-content {
        flex: 1;
        @extend .text-ellipse;
    }

    .name, p {
        color: $dark;
        font-weight: $medium;
        @extend .text-ellipse;
        min-width: initial;
        max-width: 100%;
    }
    .mail {
        color: $primary;
        font-size: $size-12;
        font-weight: $medium;
        @extend .text-ellipse;
        min-width: initial;
        max-width: 100%;
    }
    .role,
    .date {
        color: $gray-8;
        font-size: $size-12;
        font-weight: $regular;
        @extend .text-ellipse;
        min-width: initial;
        max-width: 100%;
    }
}

.review-list {
    height: 100%;
    @include flex-combinator(row, nowrap, stretch);
    .list-item {
      padding: $size-8;
      width: 150px;
      border-right: $size-1 solid $gray-2;
      @include flex-combinator(column, nowrap, center, center)
    }
    .review-list-item {
      @include flex-combinator(column, nowrap);
      gap: $size-4;
      border-radius: $size-8;
      width: 100%;
      &.child {
        @include flex-combinator(row, nowrap, center, flex-start);
      }
      &.active {
        background-color: $error-50;
        padding: $size-4;
      }
      &.bg-error-50 {
        padding: $size-4;
      }
    }
  }


.ref-list-main {
    @include flex-combinator(column, nowrap);
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    .ref-list-item {
        @include flex-combinator(row, nowrap, center, space-between);
        gap: $size-8;

        .item-title {
            @extend .text-ellipse;
            cursor: pointer;
        }
    }
}


.right-drawer-list {
    list-style: none;
    padding: 0;
    @include flex-combinator(column, nowrap);

    .date-text {
        @extend .text-ellipse;
        font-weight: $regular;
        font-size: $size-12;
    }

    .right-drawer-list-item {
        @include flex-combinator(column, nowrap);
        border-bottom: $size-1 solid $gray-2;
        height: auto;
        width: 100%;
        gap: $size-8;
        padding: $size-8;
        cursor: pointer;

        .list-title-block {
            @include flex-combinator(row, wrap, center, space-between);
            width: 100%;
            gap: $size-8;
            .title {
                @extend .text-ellipse;
            }
        }

        .list-title-block-right {
            @include flex-combinator(row, nowrap, center);
            gap: $size-2;
        }
        .overdue-row {
            @include flex-combinator(row, nowrap, center, space-between);
            gap: $size-8;
        }
    }
}


.doc-list {
    list-style: none;
    padding: 0;
    gap: $size-8;
    @include flex-combinator(column, nowrap, flex-start, start);
}
.doc-list-item {
    border: $size-1 solid $gray-2;
    background-color: $white;
    border-radius: $size-8;
    padding: $size-8;
    @include flex-combinator(row, nowrap, center, flex-start);
    gap: $size-8;
    width: 100%;

    .dragable-icon {
        opacity: 0;
        transition: 0.3s;
        &:empty {
            display: none;
        }
    }
    .kebab-menu-button,
    .edit-icon {
        opacity: 0;
        padding: 0 $size-4
    }

    &:hover,
    &:focus {
        background-color: $primary-lighter;
        color: $primary-darker;
        .dragable-icon,
        .kebab-menu-button,
        .edit-icon {
            opacity: 1;
            transition: 0.3s;
        }
    }
}

.selected-item {
    border: 2px solid $primary-3;
    border-radius: $radius-12 !important;
  }
.ref-row td{
    padding: 20px 16px;
  }
  ng-select .ng-select-container{
    .badge-pills{
      border-radius: 12px !important;
    }
  }
  .no-ref-added{
    background-color: #F8F8FA;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 12px 12px;
      border-radius: 8px;
      color: #C3CBD6;
  }
  // .selected-ref{
  //   .mat-mdc-list{
  //     max-height: 130px;
  //   }
  //   .mat-mdc-list-item{
  //     background-color: #F8F8FA;
  //     box-shadow: 0px 1px 2px 0px #1018280D;
  //     padding: 12px !important;
  //   }
  // }


  .list-item-content {
    &.disabled {
      background-color: $gray-2;
    }
  }
