body {
    .card,
    mat-card,
    .mat-card,
    .mat-mdc-card {
        background-color: $white;
        border: $size-1 solid $gray-2;
        padding: 0;
        border-radius: $size-12;
        @extend .card-shadow;

        &.box-shadow-none {
          box-shadow: none !important;
        }

        .card-header {
            border-bottom: $size-1 solid $gray-2;
            background-color: transparent;
            padding: $size-10 $size-24;
            min-height: 4.375rem;
            @include flex-combinator(row, wrap, center, space-between);

            &:empty {
                display: none;
            }
            @media (max-width: 1366px) {
                padding: $size-10 $size-15;
            }
            .title {
                margin: 0;
            }
            .header-left {
                min-height: $normal-button-height;
                @include flex-combinator(column, nowrap, flex-start);
            }
            .header-right {
                @include flex-combinator(row, wrap, center, flex-end);
            }
        }

        .card-body,
        mat-card-content,
        .mat-card-content,
        .mat-mdc-card-content {
            background-color: transparent;
            padding: $size-24;
            margin: 0;
            min-height: 0;
            @include flex-combinator(column, nowrap, initial, initial);
            @media (max-width: 1366px) {
                padding: $size-15;
            }
        }

        .card-footer,
        mat-card-actions,
        .mat-card-actions,
        .mat-mdc-card-actions {
            border: none;
            background-color: transparent;
            padding: $size-24;
            margin: 0 auto;
            width: 100%;
            @include flex-combinator(row, wrap, center, space-between);
            &:last-child {
                margin-bottom: 0;
                padding-bottom: $size-24;
            }
            @media (max-width: 1366px) {
                padding: $size-15;
                &:last-child {
                    padding-bottom: $size-15;
                }
            }
            button,
            .mat-button {
                margin: 0;
            }
        }
    }

    .cards-group {
        width: 100%;
        @include flex-combinator(row, wrap);
        gap: $size-20;

        .card {
            flex: 1;
        }
    }
}


