.countable-editor {
    @include flex-combinator(row, nowrap, center, space-between);
    padding: 0 $size-8;
    border-bottom: $size-1 solid $gray-3;
}
.card-body {
    #container {
        >.countable-editor {
            margin: -24px -24px 24px -24px;
            width: calc(100% + $size-48);
            @media (max-width: 1366px) {
                margin: -15px -15px 24px -15px;
                width: calc(100% + $size-30);
            }
        }
    }
}
