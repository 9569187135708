body {
  .mat-mdc-header-row {
    .mat-mdc-table-sticky {
      z-index: 2 !important;
      &.mat-mdc-header-cell {
        z-index: 3 !important;
      }
    }
  }
  .mat-mdc-table-sticky {
    z-index: 2 !important;
  }
  .mat-mdc-row {
    height: auto;
  }

  .mat-table-sticky {
    background-color: $white;
    z-index: 1;
  }

  .mat-table,
  mat-table,
  .mat-mdc-table {
    &.table-countable {
      width: 100%;
      margin: 0;
      font-family: $font-inter;

      .mat-cell.mat-column-groupHeader,
      .mat-mdc-cell.mat-column-groupHeader {
        margin-top: $size-20;
        background-color: $gray-50;
        color: $gray-9;
        font-family: $font-inter;
      }

      thead tr,
      .mat-header-row,
      .mat-mdc-header-row {
        height: auto;
        background-color: $gray-50;
      }

      td,
      mat-cell,
      .mat-cell,
      .mat-mdc-cell,
      .mat-footer-cell,
      .mat-mdc-footer-cell {
        box-sizing: border-box;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .mat-table-sticky-border-elem-right,
      .mat-mdc-table-sticky-border-elem-right {
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: $size-1;
          height: 100%;
          border-left: $size-1 solid $gray-3;
        }

        // &:empty {
        //   &:before {
        //     display: none;
        //   }
        // }
      }

      mat-footer-row,
      .mat-footer-row,
      .mat-mdc-footer-row {
        background-color: transparent;
        outline: $size-1 solid $gray-3;
        outline-offset: -1px;
      }

      mat-footer-cell,
      .mat-footer-cell,
      .mat-mdc-footer-cell {
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.mat-column-checkbox {
          width: 1.25rem;
          padding-right: 0;
        }
      }

      &.hover-action {
        .mat-row,
        .mat-mdc-row,
        mat-footer-row,
        .mat-footer-row,
        .mat-mdc-footer-row,
        .mat-header-row,
        .mat-mdc-header-row,
        tr {
          transition: 0s;

          .hover-action-item {
            visibility: hidden;
            @include inline-flex;
            transition: 0s;
          }
          &:hover,
          &:focus {
            transition: 0s;

            .hover-action-item {
              visibility: visible;
              transition: 0s;
            }
          }
        }

        // thead tr,
        // .mat-header-row,
        // .mat-mdc-header-row {
        //   &:hover {
        //     button:not(.show-always) {
        //       opacity: 0.7;
        //       transform: translateY(0);
        //     }
        //   }
        // }
      }

      .cell-hover-action {
        .cell-hover-action-item {
          visibility: hidden;
          transition: 0s;
        }

        &:hover {
          .cell-hover-action-item {
            visibility: visible;
            transition: 0s;
          }
        }
      }

      &.editable {
        .mat-row,
        .mat-mdc-row {
          &:hover,
          &:focus,
          .table-header-editable,
          .table-col-editable {
            background-color: $primary-lighter;
          }
        }
      }

      tr.mat-row,
      tr.mat-mdc-row,
      mat-footer-row,
      tr.mat-footer-row,
      tr.mat-mdc-footer-row {
        height: $size-44;
        position: relative;

        &.expanded-detail-row {
          td {
            padding: 0 !important;
          }

          &.cust-expanded-detail-row {
            td {
              padding: initial !important;
            }
          }
        }
      }
    }

    &.row-height-auto {
      .mat-header-row,
      .mat-mdc-header-row,
      .mat-row,
      .mat-mdc-row,
      mat-footer-row,
      .mat-footer-row,
      .mat-mdc-footer-row {
        height: auto;
      }
    }
  }

  //Rounded Table
  .mat-table.table-rounded {
    border-radius: $size-16;
  }

  // editable table row cell
  .mat-table,
  mat-table,
  .mat-mdc-table {
    width: 100%;

    &.template-edit-table {
      th,
      td {
        overflow: initial;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .mat-row,
      .mat-mdc-row {
        transition: all 0.3s ease 0s;

        &:hover,
        &:focus {
          background-color: $light-gray-2;
          transition: all 0.3s ease 0s;

          mat-form-field.fixed-label,
          .mat-form-field.fixed-label {
            .mat-form-field-infix,
            .mat-mdc-form-field-infix {
              .mat-input-element, .mat-mdc-input-element,
              .mat-select-trigger, .mat-mdc-select-trigger {
                border-color: $gray-3;
                box-shadow: initial;

                .mat-mdc-select-arrow-wrapper {
                  @include flexbox;
                }
              }
            }
          }
        }

        .cell-value {
          max-width: 380px;
        }

        .delete-m-temp-name {
          width: $size-20;
          display: none;
        }

        &:hover {
          .delete-m-temp-name {
            @include inline-flex;
          }
        }

        .mat-select {
          width: auto;
          border: transparent;
        }

        mat-form-field.fixed-label,
        .mat-form-field.fixed-label {
          .mat-form-field-infix,
          .mat-mdc-form-field-infix {
            .mat-input-element, .mat-mdc-input-element,
            .mat-select-trigger, .mat-mdc-select-trigger {
              border-color: transparent;
              box-shadow: none;

              .mat-mdc-select-arrow-wrapper {
                display: none;
              }
            }
          }
        }
      }

      .table-select-template {
        box-shadow: none;
        background-color: transparent;
        overflow: initial;

        .mat-expansion-panel-header {
          padding: 0;
          display: inline-flex;
          height: auto;
          background: transparent;

          &:hover {
            background-color: transparent;
          }
        }

        .template-select-body {
          position: absolute;
          top: 100%;
          z-index: 3;
        }
      }
    }
  }

  // Dragable table rows
  .mat-table,
  mat-table,
  .mat-mdc-table {
    &.dragable-column-table {
      .mat-column-dragable {
        padding-left: $size-8;
        padding-right: $size-8;
        padding-top: $size-18;
        width: $size-26;
        max-width: $size-26;
      }
    }
  }

  // Alignment
  .mat-table,
  mat-table,
  .mat-mdc-table {
    &.vertical-align-top {
      mat-cell,
      .mat-cell,
      .mat-mdc-cell {
        vertical-align: top;
      }
    }
  }

  .table-with-title {
    border: 1px solid $gray-2;
  }

  .table-heading {
    padding: 1.25rem 1.5rem;
  }

  .table-responsive {
    max-height: 100%;
    overflow: auto;
    width: 100%;

    &::-webkit-scrollbar {
      width: 12px;

      @media (max-width:1440px)  {
        width: 8px;
        height: 8px;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: $gray-1;
      border-radius: $radius-8;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-3;
      border-radius: $radius-8;
    }

    @media (max-width: 991px) {
      //min-height: 50vh;
      max-height: 80vh;
    }
    @media (max-width:667px) {
      min-height:initial;
      max-height: initial;
    }
  }

  .flex-table {
    @include flex-combinator(column, nowrap);
    position: relative;
    min-width: 100%;
    width: fit-content;

    .flex-table-header,
    .ft-header,
    .ft-footer,
    .flex-table-footer {
      background-color: $gray-50;

      .ft-cell,
      .ft-header-cell,
      .ft-footer-cell {
        font-size: 13px;
        font-weight: $semibold;
        min-height: $size-40;
        background-color: $gray-50;
        border-bottom: $size-1 solid $gray-2;
      }
    }

    &.sticky-header,
    &.fixed-header {
      .flex-table-header {
        position: sticky;
        top: 0;
        z-index: 2;

        &.fixed-top {
          z-index: 3;
        }
      }
    }

    .flex-table-body {
      .mat-expansion-panel {
        .mat-expansion-panel-header {
          height: auto;

          &.mat-expanded {
            height: auto;

            .mat-expansion-indicator {
              &:after {
                transform: rotate(224deg) !important;
              }
            }
          }

          .mat-expansion-indicator {
            // padding-right: $size-24;
            transform: rotate(0deg) !important;
          }
        }
      }
    }

    &.hover-action {
      .ft-row,
      .flex-table-header,
      .ft-header {
        .hover-action-item {
          visibility: hidden;
          transition: 0s;
        }

        &:hover {
          .hover-action-item {
            visibility: visible;
            transition: 0s;
          }
        }
      }
    }
  }

  .flex-table-header,
  .ft-header,
  .flex-table-footer,
  .ft-footer,
  .ft-row {
    width: 100%;
    @include flex-combinator(row, nowrap, stretch);

    .ft-cell {
      padding: $size-8;
      font-family: $font-primary;
      color: $base-color;
      @include flex-combinator(row, wrap, center, flex-start);
      @include inline-flex;
      border-bottom: $size-1 solid $gray-2;
      flex: 1;

      &:first-child {
        padding-left: $size-16;
      }

      &:last-child {
        padding-right: $size-16;
      }
    }
  }

  .ft-header-cell,
  .ft-footer-cell {
    padding: $size-8;
    font-size: 13px;
    font-weight: $semibold;
    min-height: $size-40;
    @include flex-combinator(row, wrap, center, flex-start);
    @include inline-flex;
    flex: 1;

    &:first-child {
      padding-left: $size-16;
    }

    &:last-child {
      padding-right: $size-16;
    }
  }

  .ft-cell {
    font-size: $size-14;
    font-weight: $regular;
    min-height: $size-40;
    position: relative;
  }

  table,
  .table {
    margin: 0;
    border-spacing: $size-1;
    color: $dark;

    &.table-countable {
      &.fixed-header {
        thead {
          position: sticky;
          top: 0;
          z-index: 9;
          // box-shadow: 0 $size-1 0 $gray-3;
        }
      }

      &.header-text-wraped {
        th {
          white-space: normal !important;
        }
      }

      tr {
        border-bottom: $size-1 solid $gray-2;
      }

      th {
        font-size: 13px;
        line-height: 1.3;
        font-weight: $medium;
        color: $gray-9;
        font-family: $font-inter;
        background-color: $gray-50;
        text-transform: initial;
        box-sizing: border-box;
        border-top: 0;
        border-right: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: $size-8;

        &:first-child {
          padding-left: $size-16;
        }

        &:last-child {
          padding-right: $size-16;
        }
      }

      td {
        padding: $size-8;
        font-size: $size-14;
        line-height: 1.3;
        font-weight: $regular;
        color: $gray-9;
        font-family: $font-inter;
        box-sizing: border-box;
        z-index: 2;

        &:first-child {
          padding-left: $size-16;
        }

        &:last-child {
          padding-right: $size-16;
        }
      }
    }

    &.vertical-align-top {
      td {
        vertical-align: top;
      }
    }
  }

  table.table-countable,
  mat-table.table-countable,
  .mat-table.table-countable,
  .mat-mdc-table.table-countable {
    &.cell-py-8 {
      mat-cell,
      .mat-cell,
      .mat-mdc-cell,
      .mat-header-cell,
      .mat-footer-cell,
      .mat-mdc-footer-cell,
      th,
      td {
        padding-top: $size-8;
        padding-bottom: $size-8;
      }
    }

    &.cell-px-8 {
      mat-cell,
      .mat-cell,
      .mat-mdc-cell,
      .mat-header-cell,
      .mat-footer-cell,
      .mat-mdc-footer-cell,
      th,
      td {
        padding-left: $size-8;
        padding-right: $size-8;

        &:first-child {
          padding-left: $size-16;
        }

        &:last-child {
          padding-right: $size-16;
        }
      }
    }

    &.cell-p-8 {
      mat-cell,
      .mat-cell,
      .mat-mdc-cell,
      .mat-header-cell,
      .mat-footer-cell,
      .mat-mdc-footer-cell,
      th,
      td {
        padding: $size-8;

        &:first-child {
          padding-left: $size-16;
        }

        &:last-child {
          padding-right: $size-16;
        }
      }
    }
  }

  .t-header-cell,
  th.mat-header-cell,
  .mat-header-cell,
  .mat-mdc-header-cell,
  .mat-sort-header {
    padding: $size-8;
    font-size: 13px;
    line-height: 1.43;
    font-weight: $medium;
    color: $gray-9;
    font-family: $font-inter;
    background-color: $gray-50;
    text-transform: initial;
    box-sizing: border-box;
    border-top: 0;
    border-right: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: $size-50;
    text-transform: capitalize;
    vertical-align: middle;

    &.mat-column-checkbox {
      width: 1.25rem;
      padding-right: 0;
    }

    .required {
      color: $error;
    }

    button.mat-icon-button,
    button.mdc-icon-button,
    .table-sort-button {
      vertical-align: middle;
      width: $size-16 !important;
      height: $size-16 !important;
      line-height: 1;
      padding: 0;
      @include inline-flex;
      @include align-items(center);
      @include justify-content(center);
      img {
        filter: grayscale(1);
      }

      &:not(.show-always) {
        opacity: 0;
        transform: translateY(25%);
      }

      .material-icons {
        font-size: $size-18;
        line-height: 1;
        @include sequre(auto);
        vertical-align: top;
        font-family: "Material Icons" !important;
      }

      &.active:not(.show-always) {
        opacity: 1 !important;
        transform: translateY(0) !important;
        color: $primary !important;
        img {
          filter: grayscale(0);
        }
      }
    }

    &:hover {
      button:not(.show-always),
      .table-sort-button:not(.show-always) {
        opacity: 0.6;
        transform: translateY(0);
      }
    }

    &:first-child {
      padding-left: $size-16;
    }

    &:last-child {
      padding-right: $size-16;
    }

    &.text-right,
    &.text-end {
      .mat-sort-header-container {
        @include justify-content(flex-end);
      }
    }
  }

  .table-row-editable {
    position: relative;
    // border: $size-1 solid $gray-3;
    // border-width: $size-1 0;
    background-color: $primary-lighter;
    @extend .card-shadow;

    mat-cell,
    .mat-cell,
    .mat-mdc-cell,
    .ft-cell {
      background-color: $primary-lighter;
    }

    .row-actions {
      @extend .button-group;
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
      padding: $size-6 $size-12;
      border: 1px solid $gray-3;
      border-top-width: 0px;
      background-color: $primary-lighter;
      border-radius: 0 0 $size-8 $size-8;
      gap: $size-10;
      left: $size-24;
      z-index: 2;
    }
  }

  .valueDisplay {
    width: 100%;
    border: $size-1 solid $gray-3;
    padding: 0 $size-10;
    height: $size-36;
    border-radius: $size-8;
    font-family: $font-primary;
    color: $gray-9;
    @include flex-combinator(row, nowrap, center, flex-end);
  }

  .default-row {
    .valueDisplay {
      background-color: $gray-50;
    }
  }

  .table-countable {
    &.border-none {
      tr,
      th,
      td {
        border-width: 0 !important;
      }
    }

    &.v-align-middle {
      th,
      td {
        vertical-align: middle !important;
      }
    }
  }

  mat-table.template-edit-table,
  .mat-table.template-edit-table,
  .mat-mdc-table.template-edit-table {
    .mat-row,
    .mat-mdc-row,
    .mat-mdc-header-row {
      .mat-form-field.fixed-label.category-edit {
        .mat-form-field-infix {
          .mat-select.badge-icon {
            border-radius: $size-8;
          }

          .mat-select-trigger {
            border-width: 0;
            box-shadow: initial;
            padding: 0;

            .mat-select-arrow-wrapper {
              right: 0;
            }
          }
        }
      }
    }
  }
}
body {


  .mat-table.template-edit-table {
    .mat-row {
      .mat-select-arrow-wrapper {
        opacity: 0;
        transition: all 0.3s ease 0s;
      }

      &:hover {
        .mat-select-trigger {
          padding-right: $size-16 !important;
        }

        .mat-select-arrow-wrapper {
          transition: all 0.3s ease 0s;
          opacity: 1;
        }
      }

      .mat-select-disabled {
        .mat-select-trigger {
          padding-right: 0 !important;
        }

        .mat-select-arrow-wrapper {
          opacity: 0;
          transition: all 0.3s ease 0s;
        }
      }
    }
  }

  .description-column,
  .description-cell {
    border-radius: $size-8;
    border: $size-1 solid $gray-3;
    padding: $size-10 $size-12;
    white-space: pre-line;
    min-height: 100px;

    &.disabled {
      background-color: $gray-50;
    }
  }

  .mat-row,
  .mat-mdc-row,
  tbody tr {
    &:hover {
      background-color: $primary-lighter;

      .mat-table-sticky {
        background-color: $primary-lighter;
      }
    }
  }



  .t-cell,
  td.mat-cell,
  .mat-mdc-cell,
  .mat-cell,
  mat-cell,
  td.mat-footer-cell, td.mat-mdc-footer-cell,
  th.mat-footer-cell, th.mat-mdc-footer-cell {
    padding: $size-8;
    font-size: $size-14;
    line-height: 1.3;
    font-weight: $regular;
    color: $gray-9;
    font-family: $font-inter;
    vertical-align: middle;
    min-height: $size-50;

    > button {
      vertical-align: middle;
    }

    &:first-child {
      padding-left: $size-16;
    }

    &:last-child {
      padding-right: $size-16;
    }
  }

  mat-footer-row,
  .mat-footer-row,
  .mat-mdc-footer-row {
    mat-footer-cell,
    td.mat-footer-cell, td.mat-mdc-footer-cell,
    th.mat-footer-cell, th.mat-mdc-footer-cell {
      background-color: $gray-50;
      font-weight: $bold;
      color: $gray-9;

      &:first-child {
        padding-left: $size-16;
      }

      &:last-child {
        padding-right: $size-16;
      }
    }
  }
}

body .table > :not(caption) > * > * {
  background: initial;
  border: initial;
  color: initial;
}

body {
  .vertical-align-middle {
    th,
    td {
      vertical-align: middle !important;
    }
  }

  .align-top {
    vertical-align: top !important;
  }

  .align-middle {
    vertical-align: middle !important;
  }

  .align-bottom {
    vertical-align: bottom !important;
  }

  .table-button-group {
    @include flex-combinator(row, nowrap, center, flex-end);
    gap: $size-6;
  }

  // td:empty, th:empty {
  //     display: none;
  // }

  .table-bordered {
    border-spacing: 0;

    thead {
      tr {
        box-shadow: 0 $size-1 0 $gray-3;
      }

      td,
      th {
        border-bottom-width: $size-1;
      }
    }
  }

  .table-flex {
    width: fit-content;
    min-width: 100%;
    @include flex-combinator(column, nowrap);

    thead,
    tbody {
      width: fit-content;
      min-width: 100%;
      @include flex-combinator(column, nowrap);

      tr {
        width: fit-content;
        min-width: 100%;
        @include flex-combinator(row, nowrap, stretch, flex-start);

        th,
        td {
          flex: 1;
          border-width: 0;
          @include flex-combinator(column, nowrap, start, center);
        }
      }
    }
  }
}

body {
  .status-badge {
    width: $size-14;
    height: 100%;
    text-transform: uppercase;
    position: absolute;
    left: 0px;
    top: 0;
    z-index: 1;
    @include flex-combinator(row, wrap, center, center);

    .status-badge-label {
      padding: 0px;
      font-size: $size-8;
      color: $white;
      width: 24px;
      height: 10px;
      line-height: 1;
      text-align: center;
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      position: relative;
      top: 1px;
    }
  }

  .thead-none {
    thead {
      display: none !important;
    }
  }

  .table-rounded {
    border-radius: $size-16 !important;
    overflow: hidden;
  }

  .table-rounded-bottom {
    border-radius: 0 0 $size-16 $size-16 !important;
    overflow: hidden;
  }
}

body {
  tr.mat-row.expanded-detail-row,
  tr.mat-mdc-row.expanded-detail-row {
    height: 0 !important;
    border-bottom: none;

    & td {
      border-bottom: none;
    }

    &.cust-expanded-details-row {
      border-bottom: 1px solid $gray-3;

      td {
        border-bottom: 1px solid $gray-3;
      }
    }
  }
}

body {
  .table-field-2 {
    .ng-dropdown-panel {
      min-width: 350px;
    }
  }
}

.subtotal-row {
  td:not(.mat-mdc-table-sticky),
  th:not(.mat-mdc-table-sticky) {
    font-weight: $semibold !important;
    border-bottom: $size-1 solid $black !important;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: $size-1;
      background: $black;
    }
  }
}


body {
  .mapno-dropdown-body {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }


  .filter-always {
    .mat-mdc-header-cell {
      .mat-sort-header-arrow,
      button.mdc-icon-button:not(.show-always) {
        opacity: 0.5 !important;
        transform: translateY(0) !important;
      }
      &:hover {
        .mat-sort-header-arrow,
        button.mdc-icon-button:not(.show-always) {
          opacity: 0.8 !important;
        }
      }
    }
  }
}

body {
  .bot-engagement-table-outer {
    .mat-content {
      // overflow: visible;
    }
    .amortization-table {
      width: 1898px;
      &.flex-table {
        .flex-table-body {
          .mat-expansion-panel {
            .mat-expansion-panel-header {
              position: relative;
              &:hover,
              &:focus {
                background: transparent;
              }
              .mat-expansion-indicator {
                position: sticky;
                right: 0;
                padding: $size-8;
                background: $white;
                transition: 0.3s;
                height: 100%;
                z-index: 2;
                min-height: 57px;
                @include flex-combinator(column, nowrap, center, center);
                border-left: $size-1 solid $gray-3;
                &:hover {
                  background: $gray-1;
                  transition: 0.3s;
                }
              }
            }
            .mat-expansion-panel-body {
              padding-top: $size-16;
              border-bottom: $size-1 solid $gray-3;
              @include flexbox;

              .mat-mdc-tab-labels {
                border-bottom: $size-1 solid $gray-3;
              }
            }
          }
        }
      }
    }
  }
}

app-fs-table {
  .mat-mdc-table {
    .mat-mdc-header-cell {
      vertical-align: bottom;
    }
  }
}
