.right-prev-outer {
  @include flex-combinator(column, nowrap);
  gap: 0;
  width: 100%;
  margin: 0 auto;
  padding: $size-10 $size-8;
}
.right-prev-footer {
  margin-top: auto;
  font-size: $size-12;
  background: $white;
  padding: 5px $size-15 0;
  text-align: center;
  @include flex-combinator(row, nowrap, center, center);
  position: sticky;
  bottom: 0;
}
