
.luca-launcher-btn {
  border: 0;
  @include flex-combinator(row, nowrap, center, center);
  color: $white;
  background: $primary;
  padding: $size-4 $size-6;
  border-radius: 0;
  position: fixed;
  left: 0;
  bottom: 12%;
  gap: $size-8;
  z-index: 4;
  @extend .card-shadow;
  transition: 0.3s;
  transform: translateX(-42px);

  .icon-block {
    @include flex-combinator(row, nowrap, center, center);
    @include circle($size-40);
    padding: $size-4;
    background: $primary-darker;
  }

  &:hover {
    transition: 0.3s;
    border-radius: 0 56px 56px 0;
    transform: translateX(0);
  }
}


mat-stepper.countable-stepper.chatbot-stepper {
  .mat-horizontal-stepper-header-container {
    max-width: 720px;
    margin: 0 auto;
  }
}



body {
  .bot-chat {

    .msg-body {
      p {
        font-size: $size-16;
      }
    }

    table {
      width: 100%;
      border: $size-1 solid $gray-3;
      overflow: hidden;
      border-radius: $size-8;
      border-collapse: separate;
      border-spacing: 0;
      thead {
        th, td {
          background: $gray-50;
        }
      }
      th, td {
        font-size: 13px;
        padding: $size-8;
        border-bottom: $size-1 solid $gray-3;
        &[align="right"] {
          text-align: right;
        }
      }
    }

  }

  .mat-mdc-dialog-container {
    .bot-chat {
      .ng-dropdown-panel {
        .ng-dropdown-panel-items {
          max-height: 22vh;
        }
      }
    }
  }


  .template-block-main {
    &.chat-history {
      .mat-accordion {
        .mat-expansion-panel-header {
          a.title-content,
          .title-content {
            border-bottom-color: $gray-7;
            padding-left: $size-16;
            padding-right: $size-16;
            .item-title {
              color: $gray-3;
            }
          }
        }
      }
      .list-item-content.history-list-item {
        background: rgb(35,69,114);
        background: linear-gradient(90deg, rgba(35,69,114,1) 0%, rgba(25,46,74,1) 100%);
        border-bottom-color: $gray-7;
        padding-left: $size-16;
        padding-right: $size-16;
        .item-title {
          color: $gray-3;
          font-weight: $regular;
        }
      }
    }
  }

  .extra-links-block {
    .button-title {
      color: $gray-3;
    }
  }
}


body {
  .ft-row {
    &.total-balance {
      .ft-cell {
        font-weight: $semibold;
        border-top: $size-1 solid $gray-4;
      }
    }
  }
}
body {
  .year-field {
    @include flex-combinator(row, wrap);
    width: 100%;
    @media (min-width:1920px) {
      width: auto;
    }
  }
  .expanded-window {
    .year-field {
      width: auto;
    }
  }
}

.user-chat {
  .user-chat-inner {
    @include flex-combinator(row, nowrap);
    gap: $size-8;
    width: 100%;
  }
}

.chat-block-center {
  .prompt-view {
    .top-icon-header {
      .mat-content {
        overflow: visible;
      }
    }
    .mat-expansion-panel-animations-enabled .mat-expansion-panel-content-wrapper {
      overflow: hidden;
    }
  }
}

%overlay-properties {
  content: '';
  background: rgba(0,0,0,0.5);
  @include sequre(100%);
  left: 0;
  top: 0;
  position: absolute;
  z-index: 4;
}

.c-chatbot {
  &.content-overlay {
    position: relative;

    .mat-drawer {
      position: relative;
      &:after {
        @extend %overlay-properties;
      }
    }
    .mat-drawer-content {

        position: relative;
        &:after {
          @extend %overlay-properties;
        }

    }
  }

  .chatbot-repo {
    position: absolute;
    top: 71px;
    max-width: 300px;
    height: calc(100% - 71px);
    right: 0;
    width: 100%;
    z-index: 9999999;

    .cust-right-panel {
      position: initial;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .mat-accordion {
        @include flex-combinator(column, nowrap);
        overflow: auto;
        height: 100%;
      }
    }
  }
}

body {
  .minimised-icon {
    display: none !important;
  }
  .minimised-state {
    position: relative;
    .mdc-button__label {
      position: initial;
    }
    .minimised-icon {
      display: block !important;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(0%, -40%);
    }
  }
}

.attached-list-items {
  width: 560px;
  max-width: 100%;
  @include flex-combinator(row, wrap);
  gap: $size-8;

  .attached-list-item {
    width: calc(50% - $size-4);
    @include flex-combinator(row, nowrap, center);
    gap: $size-8;
  }
}


.access-block {
  padding-top: $size-30;
  margin-top: $size-30;
  border-top: $size-1 solid $gray-2;
}


body {
  button.back-to-top {
    position: absolute;
    bottom: 10%;
    right: $size-16;
    z-index: 5;
  }
}


.chatbot-dialog {
  .context-outer {
    transition: width 0.5s ease 0;
    position: absolute;
    height: 100%;
    max-width: 80%;
    z-index: 999999;
    top: 77px;
    background: $white;
    right: 0px;
    width: initial;
    overflow: visible;
    top: 73px !important;
    height: calc(100% - 73px);
    background: $white;

    .context-btn-block {
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 75%;
      transform: translate(-100%, -50%);
      @include flex-combinator (row, nowrap);
      gap: $size-8;
      .toggle-context-btn {
        min-width: 54px;
      }
      .context-right-arrow {
        @include sequre(72px);
      }
    }
    .to-close {
      display: none;
    }
    .mat-drawer-container-has-open ~ .context-btn-block {
      .to-close {
        display: block;
      }
      .for-open {
        display: none;
      }
    }
    .mat-drawer:after {
      display: none !important;
    }
  }
  .mat-drawer-container.countable-drawer.context-window {
    width: initial;

    .mat-drawer {
      height: 100%;
      width: 0px !important;

      &[style*="visibility: visible"] {
        width: 100% !important;
      }
    }

    .ai-context {
      width: 960px;
      max-width: 100%;
      height: 100%;
      background: $white;
    }
  }
}

.select-input {
  width: 100%;
  max-width: 443px;
}
.search-input {
  width: 100%;
  max-width: 345px;
}

.general-context-bottom {
  width: 100%;
}
.context-footer-count {
  p{
      color: $gray-3;
  }
  span{
      color: $white;
  }
}

.context-save{
  color: $primary !important;
  background-color: $white !important;
  width: 100%;
  min-width: 146px;
}
.context-footer-count{
  text-align: end;
}

.circular-progress {
  position: relative;
  @include circle(65px);
  @include flex-combinator(column, nowrap, center, center);
}

.inner-circle {
  position: absolute;
  @include circle(52px);
  background-color: $primary;
  @include flex-combinator(column, nowrap, center, center);
  span {
    color: $white;
    font-size: 14px;
    line-height: 1;
    font-weight: bold;
  }
}

.general-context-top{
  overflow: auto;
}

.general-middle-section{
  overflow: auto;
}
.context-table{
  table-layout: fixed;
}
.context-outer-table{
  margin-bottom: 60px;
}


.context-toogle-button {
position: absolute;
z-index: 5;
&.close {
  left: 0;
  bottom: 20%;
}
}
.general-middle-section {
  .sticky-top {
    z-index: 4;
    background: $white;
  }
}
