%mat-button-styles {
    width: 100%;
    padding: $size-4 $size-8;
    gap: $size-8;
    // height: $size-36;
    font-size: $size-14;
    line-height: 1.43;
    color: $gray-9;
    font-family: $font-primary;
    border-bottom: $size-1 solid $gray-50;
    @include flex-combinator(row, nowrap, center);
    min-height: $size-36;
}

body {

    .mat-menu-panel,
    .mat-mdc-menu-panel {
        border: $size-1 solid $gray-3;
        @extend .card-shadow;
        max-height: 80vh !important;
        min-height: initial;

        // max-width: initial;
        // min-width: 162px;
        .scrollable-block {
            max-height: 25vh;
            overflow-y: auto;
        }

        &.ref-menu-main {
            max-width: 300px;
            min-height: 1px;
        }

        * {
            font-family: $font-primary;
        }
    }

    .map-dropdown-container {
        max-height: 50vh;
    }

    .mat-menu-content,
    .mat-mdc-menu-content {
        background-color: $white;
        height: 100%;
        overflow: auto;

        .mat-mdc-menu-item {
            width: 100%;
            padding: 0;
            min-height: inherit;

            &.button-row {
                overflow: visible;

                .mat-mdc-menu-item-text {
                    padding: 0;
                    gap: 0;
                }
            }
        }

        .mat-mdc-menu-item {
            .mat-mdc-menu-item-text {
                @extend %mat-button-styles;
            }

            &.mat-menu-item-submenu-trigger,
            &.mat-mdc-menu-item-submenu-trigger {
                padding-right: $size-20;

                .mat-menu-submenu-icon,
                .mat-mdc-menu-submenu-icon {
                    display: none;
                }

                &:after {
                    content: '';
                    position: absolute;
                    right: $size-10;
                    top: 0;
                    width: 7px;
                    height: 100%;
                    content: "\f105";
                    font-family: 'Font Awesome 6 Pro' !important;
                    display: flex;
                    align-items: center;
                    @include flex-combinator(column, nowrap, center, center);
                }
            }

            &[disabled]::after {
                left: initial;
            }
        }

        .mat-menu-item,
        .mat-mdc-menu-item-text {
            @extend %mat-button-styles;

            .item-icon {
                width: $size-18;
            }

            .item-title {
                @extend .text-ellipse;
            }

            // span,
            // .mat-mdc-menu-item-text {
            //   font-family: $font-primary;
            //   line-height: 1.43;
            // }
        }

        div,
        p {
            font-family: $font-primary !important;
        }
    }


    .mat-menu-panel,
    .mat-mdc-menu-panel {
        &.choose-temp-outer {
            width: 350px;
            max-width: 350px;

            .mat-menu-content,
            .mat-mdc-menu-content {
                overflow: hidden;
            }
        }
    }
}



.custom-active {
    .mat-ink-bar {
        display: none;
    }

    .mat-tab-label {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: $size-4;
            width: 0;
            background: $primary;
            transition: 0.3s;
            border-radius: $size-4;
        }

        &.mat-tab-label-active {
            &:after {
                width: 100%;
                transition: 0.3s;
            }
        }
    }
}

.mat-menu-panel.map-menu,
.mat-mdc-menu-panel.map-menu,
.mat-menu-panel.ls-menu,
.mat-mdc-menu-panel.ls-menu,
.map-menu,
.ls-menu {
    width: 742px !important;
    max-width: 100%;
    overflow: hidden;
    min-height: 260px;
    position: relative;

    @media (max-width: 767px) {
        max-width: 95%;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .col-done-icon,
    .col-action,
    .map-col-unasgn {
        @include fixed-width(34px);
    }

    .col-leadsheetcode,
    .map-col-mapno {
        @include fixed-width(80px);
    }

    .map-col-mapsubgrop {
        @include fixed-width(115px);
    }

    .map-col-mapname {
        min-width: 204px;
        max-width: 204px;
        padding-right: 30px;
    }

    .button-group-tabs {
        .mat-tab-header {
            padding-left: $size-8;
            margin-bottom: $size-8 !important;
        }
    }
}

.map-tabs {
    position: relative;

    .add-new-button {
        position: absolute;
        right: $size-8;
        top: 0;
        z-index: 2;
    }
}

.mat-menu-panel,
.mat-mdc-menu-panel {
    &.notes {
        width: 300px;
        max-width: 300px;
    }

    &.trial-menu {
        width: 500px;
        max-width: 100%;
    }

    &.bot-engagement-list-table {
        width: 800px;
        max-width: 100%;
    }
}

.badge-plus {
    display: inline-block;
    position: absolute;
    right: 10px;
    cursor: pointer;
    z-index: 4;

    &::after {
        content: "+";
        position: absolute;
        border: 1px solid;
        right: 0px;
        line-height: 13px;
        width: 16px;
        height: 16px;
        font-size: 16px;
        text-align: center;
        border-radius: 50%;
        color: $primary-darker;
    }
}

.expanded-row {
    td {
        vertical-align: baseline;

        &.expanded {
            white-space: unset !important;
            text-overflow: unset !important;

            .badge-plus {
                &::after {
                    content: "-";
                }
            }
        }
    }
}

body {

    .mat-menu-panel,
    .mat-mdc-menu-panel {
        &.addrow-menu-items {
            min-width: 142px;
            max-width: 100%;
        }
    }
}
