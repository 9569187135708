.cdk-virtual-scroll-viewport {
    .cdk-virtual-scroll-spacer {
        display: none;
    }
    .cdk-virtual-scroll-content-wrapper {
        position: initial;
        height: 100%;
        @include flex-combinator(column, nowrap);

        .chat-view {
            flex: 1;
            overflow: auto;
            padding-top: $size-20;
        }
        
        .reply-item {
            border-bottom: $size-1 solid $gray-50;
        }
        
        .chat-reply {
            position: relative;
            // border-top: $size-1 solid $gray-7;
            // .chat-actions {
            //     position: absolute;
            //     bottom: 4px;
            //     right: 4px;
            // }
        }
    }
}
.chat-block {
    .mat-form-field.fixed-label {
        .mat-form-field-infix {
            textarea.mat-input-element {
                border-radius: 0;
                border-width: $size-1 0 $size-1 0;
                box-shadow: none !important;
            }
        }
    }
}
