.workbook-top-expansion {
  .about-eng-header {
    position: fixed;
    top: 5px;
    left: 8px;
    width: 100px;
  }
}


.body-expansion {
  .filter-actions-button {
    position: fixed;
    top: 5px;
    right: 8px;
    width: 110px;
  }
}
.page-title-block.workbook-title-block {
  position: relative;
  @media (max-width:991px) {
    .title-block-left.header-actions {
      position: fixed;
      top: 5px;
      width: 90px;
      right: 130px;
      z-index: 2;
    }
  }
}



.sorting-block {
  .sorting-block-left,
  .sorting-block-right {
    @media (max-width: 991px) {
      width: 100%;

      .form-item {
        flex: 1;
      }
    }
  }
}
