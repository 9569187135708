/*font-family: 'Roboto', sans-serif;*/


.pages-outer {
    .page {
        &:not(:first-child) {
            margin-top: $size-20;
        }
    }
}

.page {
    // @include flex-combinator(column, nowrap);
    width: 900px;
    max-width: 100%;
    margin: 0 auto;
    // background-color: $white;
    // border: $size-1 solid $gray-3;
    // overflow: initial;
    // @extend .card-shadow;

    p,
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-arial;
        line-height: initial;
      //font-size: $size-18;
      color: $gray-10;
      font-weight: $medium;
    }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: $size-18;
  }

  strong > span {
    font-weight: $semibold;
  }

    // .page-header {
    //     @include flex-combinator(row, wrap, center, center);
    //     background-color: $gray-2;
    //     // padding: $size-20;
    //     // height: 80px;
    //     border: $size-1 dashed transparent;

    // }

    .page-body {
        @include flex-combinator(column, wrap);
        padding: $size-20;
    }

    // .page-footer {
    //     @include flex-combinator(row, wrap, center, center);
    //     background-color: $gray-2;
    //     // padding: $size-20;
    //     // height: 52px;

    //     .edit-button {
    //         display: block;
    //     }
    // }

    .edit-button {
        display: none;
    }

    .footer{
        margin-top: auto;
        max-height: 70px;
        min-height: 52px;
        background-color: $light-gray-2-color;
        width: 100%;
        & .edit {
            position: absolute;
            right: 4px;
            bottom: 45px;
            display: none;
            z-index: 6;
            cursor: pointer;

            & .material-icons {
              font-size: 16px;
              color: $black-color;
            }
          }

          &.editable:hover {

              opacity: 0.5;


            & .edit {
              display: inline-block;
            }
          }
    }

}

.page-spacing {
    padding: $size-20 $size-24;
}

.editable {
    .page-header {

        &:hover,
        &:focus {
            border-color: $primary;

            .edit-button {
                display: block;
            }
        }

        background-color: $gray-3;
        padding: $size-20;
    }
}

.page {
    background: $white;
    display: block;
    margin: 0.5cm auto 0;
    position: relative;
    border-radius: $size-12;
    border: $size-1 solid $gray-2;
    @extend .card-shadow;
    @include flex-combinator(column, nowrap);
    gap: 0;

    &.A4,
    &.a4 {
        width: 8.27in;
        min-height: 11.7in;
        padding: 40px;
        height: auto;
        max-height: inherit;
        max-width: 100%;
    }

    &.A3,
    &.a3 {
        width: 842px;
        height: 1191px;
        padding: 10px 25px;
    }

    .header {
        max-height: 200px;
        min-height: 80px;
        margin: 0 0 $size-16 0;
        background-color: $gray-50;
        font-size: $size-14;
        color: $gray-7;
    }
    p {
        >em {
          font-style: normal;
        }
      }

    .page-content {
        text-align: justify;
        color: $gray-10;
        font-family: $font-arial;
        display: block;
        width: 100%;
        // line-height: 21px;
        line-height: 1.4;

        .card {
            overflow: hidden;

            .editor {
                transform: scale(1.01) !important;
            }
        }

        .header-label {
            color: $primary-darker;
            background-color: $secondary-dark;
            padding: $size-2 $size-8;
            border-radius: $size-4 $size-4 0 0;
          //text-transform: uppercase;
            font-size: $size-14;
            font-family: $font-roboto;
          font-weight: $bold;
          opacity: 0.5;
          font-style: italic;
        }

        .page-content-body {
            border-top: $size-1 solid $gray-2;
        }

        .elementTag,
        .auto-elements {
            padding: 2px 7px 1px;
            border-radius: $size-8;
            background-color: $success-50 !important;
            border: $size-1 solid $success-dark;
            color: $success-dark;
            font-size: $size-14;
            line-height: $size-18;
            font-family: $font-roboto !important;
          font-weight: $regular;
            margin: 0;
            .pc-icon {
                font-size: $size-18;
                line-height: 1;
            }
        }

        p {
          font-size: $size-14;
            color: $gray-10;
            font-family: $font-arial;
            line-height: 21px;
            font-weight: $regular;
        }
    }



    .page-num {
        text-align: center;
        font-size: 10px;
        position: absolute;
        bottom: 16.5px;
        width: calc(100% - 80px);

        &:after {
            counter-increment: Page-counter;
            content: counter(Page-counter);
        }
    }


    .editable-block,
    .editable {
        border: $size-1 dashed transparent;
        position: relative;
        // &:after {
        //     content: '';
        //     position: absolute;
        //     right: -1px;
        //     top: -1px;
        //     background-color: $white;
        //     background-image: url('../../images/ui-uplift-icons/global/edit-pencile-Icon.svg');
        //     @include sequre($size-24);
        //     background-repeat: no-repeat;
        //     background-position: center;
        //     background-size: $size-16;
        //     border: $size-1 dashed $gray-3;
        //     visibility: hidden;
        // }

        &:hover {
            border-color: $gray-3;
            background-color: $gray-50;
            border-radius: $size-8;
            overflow: hidden;
            text-decoration: none;

            // &:after {
            //     visibility: visible;
            // }
        }
    }
}

.custom-page-layout {
    &.card,
    &.page {
        padding: $size-24 $size-40;
    }
}


.edit-wraper {
    .page {
        p.editable {
            border: none;
            position: relative;

            &:hover {
                background-color: transparent;
            }
        }
    }
    &.edit-mode-enabled {
        .page {
            p.editable {
                border: $size-1 dashed transparent;
                position: relative;
                &:hover {
                    border-color: $gray-3;
                    background-color: $gray-50;
                    border-radius: $size-8;
                    overflow: hidden;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }
}

.page-content-body {
  flex: 1;
  page-break-inside: auto;
  @include flex-combinator(column, nowrap);
  gap: 0;
  overflow: hidden;
}

.page-header,
.page-footer {
    position: relative;
    // max-height: 200px;
    // min-height: 80px;
    margin: 0;
    // background-color: $gray-50;
    font-size: $size-14;
    color: $gray-7;
    width: 100%;
    @include flex-combinator(column, nowrap, center, center);

    .edit-page-action {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .blank-header,
    .blank-footer {
      background-color: $gray-50;
      width: 100%;
      height: 100%;
      @include flex-combinator(column, nowrap, center, center);
    }
    .file-upload {
      background-color: $gray-50;
    }

    .edit-image {
      position: absolute;
      width: 100%;
      height: 100%;
      border: $size-1 dashed $gray-2;
      border-radius: $size-8;
      left: 0;
      top:0;
    }
    .sr-only {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
    }
}
.page-header {
    height: 200px;
    min-height: 200px;
}
.page-footer {
    height: 90px;
    min-height: 90px;
    align-self: flex-end;
    margin-top: auto;
}

.editable-component {
    border: $size-1 dashed transparent;
    position: relative;
    &:hover {
        border-color: $gray-3;
        background-color: $gray-50;
        border-radius: $size-8;
        overflow: hidden;
        text-decoration: none;
        cursor: pointer;
    }
}


.sign-block-outer {
    @include flex-combinator(row, nowrap, flex-start);
    gap: $size-32;
    .sign-block {
        @include flex-combinator(column, nowrap);
        gap: $size-8;
        max-width: 220px;

        .sign-block-inner {
            @include flex-combinator(column, nowrap, flex-start, center);
            gap: $size-8;

            .sign-area {
                @include flex-combinator(column, nowrap, flex-start, center);
                height: 125px;
                img {
                    max-height: 100%;
                    max-width: 100%;
                }
            }
            .sign-text {
                font-family: $font-sign;
                font-size: 40px;
                line-height: 1;
            }
        }
    }
}
.show-draft-template {
  background-repeat: no-repeat;
}

.page {
  span {
    &.font-sign {
      font-family: $font-sign;
    }
  }
}

.page {
    .tiny-mc-container {
        p > em {
            font-style: italic;
        }
    }
}
