body {
    .mat-expansion-panel-content {
        font-size: $size-14;
        line-height: 1.43;
        font-family: $font-primary;
    }

    .mat-expansion-panel-header-title {
        font-family: $font-primary;
        color: $gray-9;
        font-weight: $semibold;
    }

    .mat-expansion-panel {
        font-family: $font-primary;
        color: $black-8;
        overflow: initial;
        background-color: transparent;
    }

    .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
    .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
        background-color: $primary-lighter;
    }
    .mat-expansion-panel:not([class*=mat-elevation-z]) {
        box-shadow: none;
    }


    .folder-child-item {
        .accordian-title-child {
            padding: 0.572rem 0.715rem 0.572rem 1.3rem;

            .title-text {
                width: calc(100% - 50px);
            }
        }
    }

    .mat-accordion {
        &.bordered-accordian {
            .mat-expansion-panel {
                border: 1px solid $light-gray-4;
                border-radius: $radius-8 !important;

                &:not(:last-child) {
                    margin-bottom: 1.875rem;
                }
            }

            .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
            .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
            .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
                background-color: transparent;
            }
        }

    }

    .mat-accordion {
        .mat-expansion-panel-header {
            height: auto;

            &.dragable-header {
                .drag-btn {
                    opacity: 0;
                    transition: 0.3s;
                }

                .left-toggle-icon {
                    img {
                        transform: rotate(180deg);
                        transition: 0.3s;
                    }
                }

                &.mat-expanded {
                    .left-toggle-icon {
                        img {
                            transform: rotate(0deg) !important;
                            transition: 0.3s;
                        }
                    }
                }

                &:hover,
                &:focus {
                    .drag-btn {
                        opacity: 1;
                        transition: 0.3s;
                    }
                }
            }

            &.editable-header {
                &:before {
                    display: none;
                }

                .editable-header-main {

                    .edit-label-icon,
                    .delete-temp-btn {
                        display: none;
                        transition: 0.3s;
                    }

                    .form-control {
                        padding: 0;
                        border: 0;
                        background-color: transparent;
                        color: $white;

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }

                &:hover,
                &:focus {
                    .editable-header-main {

                        .edit-label-icon,
                        .delete-temp-btn {
                            @include inline-flex;
                            transition: 0.3s;
                        }
                    }
                }


            }

        }
    }

    .auto-header-height {
      .mdc-tab-indicator {
        display: none !important;
      }
        .mat-mdc-tab-header {
          height: auto;
        }
        .mat-tab-label,
        .mat-mdc-tab {
            height: auto;
            padding: 0;

            .mat-radio-button,
            .mat-mdc-radio-button {
                margin: 0;
                padding: 0;

                .mat-radio-container,
                .mdc-radio {
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                }

                .mat-radio-label-content {
                    padding: 0;
                }
            }

            .auth-tab {
                background: $tabsbg;
                border: 2px solid $light-gray-4;
                border-radius: $radius-12;
                margin: 0px;
                padding: 1rem 2.5rem 1rem 1rem;

                p {
                    margin: 0;
                    white-space: initial;
                }
            }
        }

        // .mat-tab-label-active {
        .mat-radio-checked,
        .mat-mdc-radio-checked {
            .auth-tab {
                background: $tabsactive;
                border-color: $tabsbrdactive;
            }
        }

        .mat-tab-header {
            border-bottom: none;
        }

        .mat-ink-bar {
            display: none;
        }

        svg {
            margin: 12px 0px 0px 0px;
        }

        .mat-radio-button {
            margin-top: 1rem;
        }

        .mat-tab-label {
            .mat-tab-label-content {
                white-space: initial;
            }
        }
    }

    .mat-expansion-panel-header {
        width: 100%;

        &.discription-right {
            .mat-content {
                @include justify-content(space-between);

                .mat-expansion-panel-header-description {
                    flex-grow: 0;
                    padding-right: 2rem;
                }
            }

            .mat-expansion-panel-header-title {
                color: $secondary-2;
            }
        }

        &.height-auto {
            height: auto;
        }
    }

    .mat-expansion-panel.mat-expansion-panel-spacing {
        margin: 0;
    }

    .mat-accordion .mat-expansion-panel,
    .mat-expansion-panel {
        &.card {
            border-radius: $size-8 !important;
            @extend .card-shadow;

            .mat-expansion-panel-header {
                &.card-header {
                    border-width: 0;
                    min-height: initial;
                    width: 100%;
                    height: auto;
                    padding: $size-14 $size-24;

                    // pointer-events: none;
                    &:not(.mat-expanded) {

                        &:hover,
                        &:hover:not([aria-disabled=true]),
                        &:focus,
                        &.mat-focused {
                            background-color: transparent;
                        }
                    }

                    &.mat-expanded {
                        border-width: $size-1;
                    }

                    .mat-content {
                        @include flex-combinator(row, wrap, center, space-between);
                        padding-right: $size-24;

                        // pointer-events: none;
                        * {
                            pointer-events: initial;
                        }
                    }

                    .toogle-button {
                        position: absolute;
                        right: 0;
                        height: 100%;
                        width: 50px;
                        padding: 0;
                        font-size: 0;
                        border: 0;
                        box-shadow: none;
                        background-color: transparent;
                    }

                    @media (max-width: 1366px) {
                        padding: 0.625rem 0.9375rem;
                    }
                }
            }

            .mat-expansion-panel-body {
                padding: 0;
            }
        }
    }
}



body {
  mat-expansion-panel.top-icon-header {

    .mat-expansion-indicator {
      display: none !important;
    }

    mat-expansion-panel-header {
      padding: 0;

      &:hover {
        background: $secondary-lighter !important;
      }
      .mat-content {
        margin: 0;
        .mat-expansion-panel-header-title {
          margin: 0;
        }
        &.mat-content-hide-toggle {
          .togglable-icon {
            display: none !important;
          }
        }
      }
      .mat-panel-title {
        margin: 0;
      }
    }
    .panel-title {
      width: 100%;
      @include flex-combinator(column, nowrap, center, center);
      gap: $size-4;
      padding: $size-16;
      color: $primary !important;
      .togglable-icon {
        transform: rotate(-90deg);
      }
      .title {
        color: $primary !important;
      }
    }

    &.mat-expanded {
      .panel-title {
        .togglable-icon {
          transform: rotate(90deg);
        }
      }
    }
  }
}


body {
  mat-expansion-panel.plan-expansion {
    mat-expansion-panel-header {
      padding: 0;
      &:hover {
        background: transparent !important;
      }
      .mat-expansion-indicator {
        display: none !important;
      }
      mat-panel-title {
        margin: 0;
      }
      .plan-expansion-header {
        @include flex-combinator(row, nowrap, center);
        width: 100%;
        gap: $size-8;

        .title {
          @extend .text-ellipse;
          flex: 1;
          font-size: $size-16;
        }

        .toggle-icon {
          @include sequre($size-20);
          @include flex-combinator(row, nowrap, center, center);
          &:after {
            position: absolute;
            top: 50%;
            left: 0;
            content: "\f055";
            font-family: 'Font Awesome 6 Pro' !important;
            @include sequre(100%);
            z-index: 1;
            font-size: $size-20;
          }
        }
      }
      &.mat-expanded {
        .plan-expansion-header {
          .toggle-icon {
            &:after {
              content: "\f056";
            }
          }
        }
      }
    }

    .mat-expansion-panel-body {
      padding: 0;
    }
    .plan-expansion-body {
      @include flex-combinator(column, nowrap);
      gap: $size-16;
    }
  }
}


body {
  .mat-expansion-panel-header[aria-disabled=true] {
    .panel-title {
      background: $gray-1;
      cursor: auto;
      .title {
        color: $gray-5 !important;
      }
    }
  }
}
