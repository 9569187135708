/* MODAL STYLES
-------------------------------*/
jw-modal {
    /* modals are hidden by default */
    display: none;
    width: 50px;
  
    .jw-modal {
      /* modal container fixed across whole screen */
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      /* z-index must be higher than .jw-modal-background */
      z-index: 1000;
      /* enables scrolling for tall modals */
      overflow: auto;
  
  
      .jw-modal-body {
        //padding: 16px;
        background: white;
        width: 350px;
        height: auto;
        float: right;
        position: relative;
        border-radius: 5px;
        top: 55px;
        left: 77px;
        -webkit-animation: jw-modal-body 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: jw-modal-body 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }
    }
  
    .jw-modal-background {
      /* modal background fixed across whole screen */
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      /* semi-transparent black  */
      background-color: transparent;
      opacity: 0.75;
      /* z-index must be below .jw-modal and above everything else  */
      z-index: 900;
    }
  }
  
  @-webkit-keyframes slideDown {
    0%, 100% {
      -webkit-transform: translateY(-50px);
    }
  
    10%, 90% {
      -webkit-transform: translateY(0px);
    }
  }
  
  @-moz-keyframes slideDown {
    0%, 100% {
      -moz-transform: translateY(-50px);
    }
  
    10%, 90% {
      -moz-transform: translateY(0px);
    }
  }
  
  body.jw-modal-open {
    /* body overflow is hidden to hide main scrollbar when modal window is open */
    overflow: hidden;
  }
  
  @-webkit-keyframes jw-modal-body {
    0% {
      -webkit-transform: translateZ(0) translateX(0);
      transform: translateZ(0) translateX(0);
    }
  
    100% {
      -webkit-transform: translateZ(160px) translateX(-100px);
      transform: translateZ(160px) translateX(-100px);
    }
  }
  
  @keyframes jw-modal-body {
    0% {
      -webkit-transform: translateZ(0) translateX(0);
      transform: translateZ(0) translateX(0);
    }
  
    100% {
      -webkit-transform: translateZ(160px) translateX(-100px);
      transform: translateZ(160px) translateX(-100px);
    }
  }
  