.slider {
    .ng-image-slider {
        .ng-image-slider-container {
            .main {
                .icons {
                    background-color: transparent;
                    border-radius: $size-8;
                    border: $size-1 solid $gray-3;
                    font-size: 0;
                    @include flex-combinator(row, nowrap, center, center);

                    &:after {
                        @include sequre(100%);
                        content: "\f106";
                        position: absolute;
                        left: 0;
                        top: 0;
                        font-family: 'Font Awesome 6 Pro' !important;
                        font-size: $size-16;
                    }
                    &.prev {
                        &:after {
                            transform: rotate(-90deg);
                        } 
                    }
                    &.next {
                        &:after {
                            transform: rotate(90deg);
                        } 
                    }
                }
                .main-inner {
                    .img-div {
                        img {
                            margin: 0 -3px !important;
                            border-radius: 0 !important;
                        }
                    }
                }
            }
        }
    }
}